import Indexnavbar2 from "components/Navbars/Indexnavbar2";
import React, { useState, useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer2 from "components/Footers/Footer2";
import isoimg1 from "assets/img/isoimg1.jpg";
import cardimg1 from "assets/img/cardimg1.png";
import cardimg2 from "assets/img/cardimg2.png";
import cardimg3 from "assets/img/cardimg3.png";
import isoimg5 from "assets/img/isoimg5.jpg";
import isoimg6 from "assets/img/isoimg6.jpg";
import awards from "assets/img/awards.png";
import officebag from "assets/img/officebag.png";
import experience from "assets/img/experience.png";
import choose1 from "assets/img/choose-img1.jpg";
import choose2 from "assets/img/choose-img2.jpg";
import choose3 from "assets/img/choose-img3.jpg";
import clientbg from "assets/img/client-bg.jpg";
import elegantimg from "assets/img/elegantimg.png";
import client2 from "assets/img/client-img2.png";
import client3 from "assets/img/client-img3.png";
import isoimg2 from "assets/img/isoimg2.jpg";
import success from "assets/img/success.png";
import process from "assets/img/processi.jpeg";
import CLARITYPATHWAYS from "assets/img/clarity-pathways2.png";
import contactimg from "assets/img/contact-img.png";
import contactimg1 from "assets/img/contactimg1.jpg";
import about from "assets/img/about.jpeg";
import serviceimg from "assets/img/serviceimg.jpeg";
import whychoose from "assets/img/why-choose.jpg";
import Profile from "views/Profile";
export default function Homepage1() {
  const [activeTab, setActiveTab] = useState("mission");
  return (
    <section>
      <Indexnavbar2 />

      {/*section-1*/}
      <section className="xl:block lg:block md:block sm:block block bg1 figtree xl:pt-10  ">
        <div className="container mx-auto xl:pt-8 xl:ml-56 md:ml-32 lg:ml-8 :pt-8 ml-6  ">
          <div className="flex flex-wrap ">
            <div className="xl:mt- xl:pt-36 lg:pt-20 xl:pr-96 xl:mr-32  sm:px-3 xl:text-left sm:text-center xl:ml-0 md:ml-16 ">
              <h1 className="xl:text-4xl text-2xl xl:pt-4 sm:ml-3 text-white fobnt-bold figtree font-bold xl:mt-0 mt-6">
              If you aim to expand your business,<br/>It is essential to first standardize<br/> your Operations.
              </h1>
              <p className="xl:text-base text-xl text-white xl:pt-6 xl:mr-0 mr-6 xl:mt-0 mt-6">
              We are a catalyst for growth and potential <br/>  in the SMEs sector
              </p>
              <div className="flex flex--wrap  xl:mt-5 gap-4">
                <button className="xl:text-base rounded-md bg2 text-white xl:px-6 xl:py-3 px-3 py-3 xl:ml-0  xl:mt-0 mt-6">
                  <a>Book an Appointment</a>
                </button>
                <button className="xl:text-base rounded-md bg2 text-white xl:px-6 xl:py-3 px-3 py-3 xl:mt-0  mt-6  xl:ml-0 ">
                  <a>Our Services</a>
                </button>
              </div>
            </div>

               <div className="xl:w-80 xl:ml-0 md:ml-0 lg:ml-0 ml-12">
                  <img
                    className="xl:w-80 md:ml-8 xl:ml-0 -ml-12 xl:mt-0 mt-6 "
                    src={CLARITYPATHWAYS}
                    alt=""
                  />
                  </div>
                </div>
            </div>
      </section>

      {/*section-2*/}
      <section className="xl:block lg:block md:block sm:block block figtree container mx-auto figtree xl:mt-16 xl:pb-20">
        <div className="flex justify-between">
          <h1 className="xl:text-3xl lg:text-3xl md:text-3xl text font-bold  xl:-pt-2 xl:ml-0 md:ml-80 lg:ml-96 ml-36  mt-6">
            Our Services
          </h1>
        </div>

        <div className="grid xl:grid-cols-2 sm:grid-cols-1 xl:mt-10 ">
          <div class="relative w-full max-w-md overflow-hidden xl:-mr-10  ">
            <img
              class="xl:w-11/12 xl:h-350 w-10/12 object-cover mt-5 rounded-xl xl:-mr-10 xl:ml-0 md:ml-16 ml-8"
              src={serviceimg}
              alt=""
            />
          </div>

          {/*2nd grid*/}
          <div>
            <div className="grid xl:grid-cols-2 sm:grid-cols-1 gap-4 xl:pt-4 xl:mx-0 mx-8 xl:mt-0 mt-8">
              <div class="relative w-full max-w-md overflow-hidden rounded-lg bg3 xl:-ml-4">
               

                <div class=" text xl:p-4 p-4 xl:pt-5 ">
                  
                  <h3 class="xl:text-2xl font-semibold xl:mb-10 text1">
                  About ISO Standards Consulting:
                  </h3>
                  <p class="xl:text-base xl:mb-4">
                  ISO standards are internationally recognized guidelines that help 
                  organizations improve their performance and ensure consistent 
                  quality. By implementing ISO standards, businesses can enhance 
                  their reputation, streamline operations, and reduce costs.
                  </p>
                  <a href="#" class="text1 font-bold text-sm xl:mt-5">
                    Read more &rarr;
                  </a>
                </div>
              </div>

              <div class="relative w-full max-w-md overflow-hidden rounded-lg bg3 xl:ml-1 xl:mt-0 mt-4 xl:mb-0 mb-4">

                <div class=" text xl:p-4 p-4 xl:pt-5">
                  <h3 class="xl:text-2xl font-semibold xl:mb-8 text1">
                  Optimized Operational Systems for SMEs .
                  </h3>
                  <p class="xl:text-base xl:mb-5">
                  Are you an SME struggling with inefficiencies, high costs, 
                  and slow growth? Our expert consultants can help you streamline 
                  your operations, reduce waste, and boost productivity. We'll work 
                  closely with you to identify bottlenecks, optimize processes, and 
                  implement Lean principles.
                  </p>
                  <a href="#" class="text1 font-bold text-sm ">
                    Read more &rarr;
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
     <div className=" hidden xl:block bg18 xl:px-20 px-20 xl:py-16 py-16">
     <div className="xl:px-20 px-0">
      <Profile />
      </div>
      </div>
      {/*section-3*/}
      <div className="bg3">
      <section className="xl:block lg:block md:block sm:block block container mx-auto xl:pt-20 figtree">
        <div className="grid xl:grid-cols-2 grid-cols-1 xl:pt-16 xl:pb-20 pt-16 pb-12">
          <div>
            <div className=" xl:w-8/12 xl:pb-8 xl:pt-6 xl:mt-10">
              <img
                className=" rounded-lg xl:w-12/12 w-10/12 xl:ml-5 md:ml-16 ml-8 xl:-mt-28 relative"
                style={{ zIndex: 1 }}
                src={about}
                alt=""
              />
            </div>
          </div>

          <div className="xl:-mt-12 mt-12 xl:px-10 px-8 xl:-ml-28 ">
            <p className="xl:text-lg text-lg  text1 font-semibold xl:pb-0 pb-4">About Us</p>
            <h1 className="xl:text-3xl lg:text-3xl md:text-3xl font-semibold text xl:pt-4 xl:pb-0 pb-4 ">
              We Are Standing With <span className="text1">12+</span>
               Years Of Experience
            </h1>
            <p className="xl:text-base text2 font-semibold xl:pt-5 xl:pb-0 pb-4">
            Enthusiasm for empowering SMEs growth
            </p>
            <p
              className="xl:text-base text2  xl:pt-4 xl:px-0 px-10"
              style={{ textAlign: "justify" }}
            >
            At Clarity Pathways Consulting we empower SMEs to thrive by streamlining processes,
            optimizing systems and driving sustainable growth. 
            Our mission is to boost efficiency and productivity helping businesses deliver outstanding products and services to global customers. 
            Through a tailored Optimized Operational Systems approach we partner with organizations to overcome challenges, 
            enhance operations and achieve lasting success in a competitive market.
            </p>

            <div className="flex  xl:gap-8 md:gap-12 xl:mt-8 mt-8">
              <div className="flex flex-wrap">
                <div className="w-16 h-16 rounded-md bg3">
                  <div className="center-button">
                    <img className="xl:w-8 xl:pt-4" src={success} alt="" />
                  </div>
                </div>
                <div className="xl:ml-4">
                  <h1 className="xl:text-xl font-bold text">15+</h1>
                  <p className="text-sm text2">Successfull Projects</p>
                </div>
              </div>

              <div className="flex flex-wrap xl:ml-0 md:ml-12">
                <div className="w-16 h-16 rounded-md bg3">
                  <div className="center-button">
                    <img className="xl:w-8 xl:pt-4" src={officebag} alt="" />
                  </div>
                </div>
                <div className="xl:ml-4">
                  <h1 className="xl:text-xl font-bold text">12+</h1>
                  <p className="text-sm text2">Years Experience</p>
                </div>
              </div>

              <div className="flex flex-wrap xl:ml-0 md:ml-12">
                <div className="w-16 h-16 rounded-md bg3">
                  <div className="center-button">
                    <img className="xl:w-8 xl:pt-4" src={experience} alt="" />
                  </div>
                </div>
                <div className="xl:ml-4 ml-2 ">
                  <h1 className="xl:text-xl font-bold text">8+</h1>
                  <p className="text-sm text2">Experts</p>
                </div>
              </div>
            </div>

            <div className=" xl:mt-12 xl:ml-0  md:ml-64 ml-20 mt-6  ">
              

              <button className="xl:text-base rounded-md bg-gradient text-white font-semibold xl:px-6 xl:py-3 xl:px-6 xl:py-3 px-6 py-2">
                <a href="/">Get A Quote</a>
              </button>
            </div>
          </div>
        </div>
      </section>
      </div>

      {/*section-4*/}
      <section className="xl:block lg:block md:block sm:block block figtree">
        <div className="grid xl:grid-cols-2 grid-cols-1 container xl:ml-48 lg:ml-10 p-8 xl:pt-20 xl:pb-20 pt-6 pb-10">
          <div>
            <p className="xl:text-lg text-md text1 font-semibold xl:pt-12 pt-12 xl:pb-0 pb-8 xl:text-left sm:text-center">Why Choose Us</p>
            <h1 className="xl:text-3xl text-3xl font-semibold text xl:pt-4 xl:text-left sm:text-center">
              Simplify your processes for higher productivity
            </h1>
            <div>
              {/* Tab Buttons */}
              <div className="flex  xl:mt-8 mt-8  xl:gap-4 gap-6">
                <button
                  onClick={() => setActiveTab("mission")}
                  className={`xl:text-lg rounded-md bg-white text xl:px-6 xl:py-3 lg:px-6 lg:py-3 md:px-6 md:py-3    font-semibold shadow-xl ${
                    activeTab === "mission" ? "text-blue-500" : "text-gray-700"
                  }`}
                >
                 Our Vision
                </button>
                <button
                  onClick={() => setActiveTab("vision")}
                  className={`xl:text-lg rounded-md bg-white text xl:px-6 xl:py-3 font-semibold shadow-xl ${
                    activeTab === "vision" ? "text-blue-500" : "text-gray-700"
                  }`}
                >
                 Our Mission
                </button>
                <button
                  onClick={() => setActiveTab("values")}
                  className={`xl:text-lg rounded-md bg-white text xl:px-6 xl:py-3 font-semibold shadow-xl ${
                    activeTab === "values" ? "text-blue-500" : "text-gray-700"
                  }`}
                >
                  Our Values
                </button>
              </div>

              {/* Tab Content */}
              <div className="mt-8">
                {activeTab === "mission" && (
                  <div>
                    <h2 className="text-xl font-bold text">Our Vision</h2>
                    <div className=" xl:mt-4">
                      <p
                        className="xl:text-base text2 "
                        style={{ textAlign: "justify" }}
                      >                        
                        To position India as a global leader in delivering efficient, 
                        high-quality products and services.
                      </p>
                    </div>

                  </div>
                )}
                {activeTab === "vision" && (
                  <div>
                    <h2 className="text-xl font-bold text">Our Mission</h2>
                    <div className=" xl:mt-4">
                      <p
                        className="xl:text-base text2 "
                        style={{ textAlign: "justify" }}
                      >
                        Our mission is to empower over one million SMEs to enhance 
                        their efficiency and productivity and enabling them to deliver exceptional, 
                        customer-delighting products and services worldwide
                      </p>
                    </div>
                  </div>
                )}
                {activeTab === "values" && (
                  <div>
                    <h2 className="text-xl font-bold text">Our Values</h2>
                    <div className=" xl:mt-4">
                      <p
                        className="xl:text-base text2 "
                        style={{ textAlign: "justify" }}
                      >
                        Our business values focus on driving operational excellence and sustainable growth.
                      </p>
                    </div>

                    <div className="flex xl:mt-6 mt-4">
                     
                        <p className="text-center text-sm">➣</p>
                     
                      <p className="xl:text-base text2 hovertext ml-2 font-semibold">
                         Improve efficiency in People, Equipment & Process
                      </p>
                    </div>

                    <div className="flex xl:mt-2">
                     
                        <p className="text-center text-sm">➣</p>
                      
                      <p className="xl:text-base text2 hovertext ml-2 font-semibold">
                        Customized Solutions Aligned to Goals
                      </p>
                    </div>

                    <div className="flex xl:mt-2">
                      
                        <p className="text-center text-sm">➣</p>
                     
                      <p className="xl:text-base text2 hovertext ml-2 font-semibold">
                       Productivity Improvement
                      </p>
                    </div>

                    <div className="flex xl:mt-2">
                     
                        <p className="text-center text-sm">➣</p>
                     
                      <p className="xl:text-base text2 hovertext ml-2 font-semibold">
                      Setup the business required strategies and systems.
                      </p>
                    </div>
                    <div className="flex xl:mt-2">
                      
                        <p className="text-center text-sm">➣</p>
                   
                      <p className="xl:text-base text2 hovertext ml-2 font-semibold">
                      Standardize the business processes.
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="xl:ml-32 xl:mt-16 mt-10">
            <div>
              <div className=" xl:w-12/12">
                <img
                  className="rounded-xl xl:w-12/12  relative"
                  style={{ zIndex: 1 }}
                  src={process}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*section-5*/}
      <section className="xl:block lg:block md:block sm:block block  figtree">
        <div
          className="relative xl:h-700 xl:pb-0 pb-20 bg3"
          
        >
          {/* Overlay */}
          <div
          
          ></div>

          <div
            className=" container mx-auto xl:pt-24 xl:px-0 px-8 xl:pt-0 pt-4"
            style={{ position: "relative", zIndex: 2 }}
          >
            <div className="grid xl:grid-cols-2 grid-cols-1 ">
              <div>
                <p className="xl:text-lg text1 font-semibold text-white">
                  Clients Review
                </p>
                <h1 className="xl:text-3xl text-3xl md:text-3xl lg:text-3xl font-semibold xl:pt-4 text-black xl:pb-0 pb-4">
                  Our Lovely Clients Get Helped<br></br> From Our Company
                </h1>
              </div>
            </div>
             
            <div className="grid xl:grid-cols-3 grid-cols-1 xl:mt-10 gap-4">
              <div className="hover-container xl:h-64w xl:py-0 md:py-4 py-4">
                <div className=" rounded-lg xl:pb-4 xl:px-4 px-3">
                  <p className="xl:text-base text2 justified-text xl:pt-10">
                  Prior to engaging with Clarity Pathways Consulting, we were struggling 
                  to balance project deadlines with increasing customer demands. Their 
                  expertise helped us streamline our operations, improve productivity, 
                  and deliver projects on time, every time
                  </p>
                  <h1 className="xl:text-lg font-semibold text text-left xl:pt-4">
                    Mr.Ramkumar Ramakrishnan
                  </h1>
                  <p className="xl:text-sm text xl:pt-2 text-left">
                    CEO Of Elegant Carve Infotech Private Limited
                  </p>
                  <div className="flex justify-left">
                    <span className="orange text-center xl:text-xl xl:pt-1">
                      ★★★★★
                    </span>
                  </div>
                </div>
              </div>

              <div className="hover-container xl:h-64w xl:py-0 md:py-4 py-4">
                <div className=" rounded-lg xl:pb-4 xl:pl-4 xl:px-4 px-4 xl:mt-0 mt-2">
                  
                  <p className="xl:text-base text2 justified-text xl:pt-10 xl:px-3">
                  We don’t know how to standardize and improve our process, 
                  after partnering with Clarity Pathways I have a 
                  confidence to build the systems in our Operations
                  </p>
                  <h1 className="xl:text-lg font-semibold text text-left xl:pt-16 pt-8">
                  Mr. Elumalai
                  </h1>
                  <p className="xl:text-sm text xl:pt-2 text-left pt-2">
                    CEO Of V- Fab Solutions
                  </p>
                  <div className="flex justify-left">
                    <span className="orange text-center xl:text-xl xl:pt-1">
                      ★★★★★
                    </span>
                  </div>
                </div>
              </div>

              <div className="hover-container xl:h-64w xl:py-0 md:py-4 py-4">
                <div className=" rounded-xl xl:pb-8 xl:pt-8">
                  <p className="xl:text-base text2 justified-text xl:pt-2 xl:px-4 px-4 xl:mb-6">
                  As we are partnering with Clarity Pathways It has been a game-changer. We've 
                  seen significant improvements in our operations, including reduced waste, 
                  enhanced quality control, and faster delivery times. 
                  </p>
                  <h1 className="xl:text-lg font-semibold text text-left xl:pt-4 xl:ml-4 ml-4 pt-2">
                  Mr. Ajith Kumar
                  </h1>
                  <p className="xl:text-sm text xl:pt-2 text-left xl:ml-4 ml-4 pt-2">
                    CEO Of Vivasayi Fresh
                  </p>
                  <div className="flex justify-left xl:ml-4 ml-4">
                    <span className="orange text-center xl:text-xl xl:pt-1">
                      ★★★★★
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*section-6*/}
      <section
        className="xl:block lg:block md:block sm:block block figtree container mx-auto xl:pb-24 xl:pb-24 xl:mb-0 md:mb-8 xl:-mt-16 mt-5 bg-white rounded-md "
        style={{ position: "relative", zIndex: 1 }}
      >
        <div
          className="xl:h-800 h-900 shadow-xl rounded-md "
          style={{
            backgroundImage: `url(${contactimg})`,
            width: "100%",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div
            className="xl:ml-16 xl:pt-32"
            style={{ position: "relative", zIndex: 2 }}
          >
            <div className="xl:w-6/12 bg26 border13 shadow-md rounded-lg xl:-mt-16 -mt-10 xl:pt-2 pt-4  xl:pl-0 pl-10 xl:pr-0 xl:pl-4 pr-4  xl:py-3 xl:px-4 xl:-pr-32">
              <h1 className="xl:text-3xl text font-semibold">
                Consulting Quote
              </h1>
              <p className="xl:text-base text xl:pt-4">
              Are you an SME struggling with inefficiencies, 
              high costs, and <br/> slow growth?. Contact us today for a free consultation.
              </p>
              <form>
                <div className="flex flex-wrap xl:mt-8 gap-4 xl:ml-0   xl:mt-0 mt-5">
                  <div className="xl:w-12/12">
                    <input
                      className="xl:w-12/12 rounded-md bg5 border-4 xl:py-3"
                      type="text"
                      id="text"
                      placeholder="Name"
                    />
                  </div>
                  <div className="xl:w-12/12 ">
                    <input
                      className="xl:w-12/12 rounded-md bg5 border-4 xl:py-3"
                      type="text"
                      id="text"
                      placeholder="Email"
                    />
                  </div>
                </div>

                <div className="flex flex-wrap xl:mt-4 gap-4 xl:ml-0 xl:mt-0 mt-5 ">
                  <div className="xl:w-12/12">
                    <input
                      className="xl:w-12/12 rounded-md bg5 border-4 xl:py-3"
                      type="number"
                      id="number"
                      placeholder="Phone"
                    />
                  </div>
                  <div className="xl:w-12/12 ">
                    <input
                      className="xl:w-12/12 rounded-md bg5 border-4 xl:py-3"
                      type="text"
                      id="text"
                      placeholder="Your Subject"
                    />
                  </div>
                </div>

                <textarea
                  className="xl:w-12/12 w-10/12 rounded-md bg5 border-4 xl:mt-4 mt-4 xl:ml-0 "
                  id="message"
                  name="message"
                  placeholder="Your message..."
                  rows="5"
                ></textarea>

                <button
                  className="bg-gradient font-semibold rounded-md xl:px-64 xl:py-3 xl:my-0 my-8 xl:mb-0 mb-4 mt-4 px-6 py-2 ml-24  xl:mt-6 xl:ml-2   xl:mt-24 text-white"
                  type="submit"
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
      <Footer2 />
    </section>
  );
}
