import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const GallerySlider1 = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 50000, // Adjusted speed for smoother animation
    slidesToShow: 1,
    arrows: false,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: "linear",
  };

  const sliderContainerStyle = {
    overflow: "hidden",
    width: "100%", // Default to 100%
  };

  const imageContainerStyle = {
    display: "flex",
    flexDirection: "row",
  };

  const movingImageStyle = {
    width: "100%",
    flexShrink: 0,
  };

  const keyframesStyle = `
    @keyframes scroll-left {
      0% {
        transform: translateX(0%);
      }
      100% {
        transform: translateX(-100%);
      }
    }
  `;

  const responsiveStyles = `
    @media (max-width: 450px) {
      .slider-container {
        width: 800px !important; /* Set width to 800px on mobile */
      }
      img {
        height: auto;
        width: 100%;
        max-width: 100%;
      }
      
    }
  `;

  return (
    <section>
      <style>{keyframesStyle}</style>
      <style>{responsiveStyles}</style>
      <div className="slider-container" style={sliderContainerStyle}>
        <Slider {...settings}>
         
        </Slider>
      </div>
    </section>
  );
};

export default GallerySlider1;
