import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";

// layouts

import Admin from "layouts/Admin.js";
import Auth from "layouts/Auth.js";

// views without layouts

import Landing from "views/Landing.js";
import Profile from "views/Profile.js";
import Index from "views/Index.js";

//about us
import Aboutt from "about/aboutpage/Aboutt.js";

// contact us
import Contactus from "about/aboutpage/Contactus";
import About2 from "about/aboutpage/About2";
import Approach from "Approach/isopage";
import Applications from "components/Dropdowns/ApplicationsContent";
import Whymarslab from "components/Dropdowns/Whymarslab";
import Form from "components/Dropdowns/Form";
import Leadership from "components/Dropdowns/Leadership";
import Cloud1 from "components/Cloud/Cloud1";
import Service from "components/Homepage/Service";
import CloudService from "components/Homepage/CloudService";
import PrivateCloudService from "components/Homepage/PrivateCloudService";
// import PublicCloudService from "components/Homepage/PublicCloudService";
import ModernOperations from "components/Homepage/ModernOperations";
import CloudMigration from "components/Homepage/CloudMigration";
import Indexnavbar2 from "components/Navbars/Indexnavbar2";
import Homepage2 from "components/Homepage/Homepage2"
import Footer2 from "components/Footers/Footer2";
import About from "about/aboutpage/About";
import Portfolio from "components/Homepage/Portfolio";
import PrivacyPolicy from "components/Homepage/PrivacyPolicy";
import TermsofUse from "components/Homepage/TermsofUse";
import Slider from "react-slick";
import GallerySlider1 from "views/GallerySlider1";
import { Toaster } from "react-hot-toast";

// import { Form } from "formik";



ReactDOM.render(
  <>
  <div><Toaster/></div>
  <BrowserRouter>
    <Switch>
      {/* add routes with layouts */}
      {/* <Route path="/admin" component={Admin} />
      <Route path="/auth" component={Auth} /> */}
      {/* <Route path="/aboutt" component={Aboutt} /> */}
      {/* add routes without layouts */}
      {/* <Route path="/landing" exact component={Landing} />
      <Route path="/profile" exact component={Profile} /> */}

      {/* Homepage */}

      {/* Aboutus page */}
      <Route path="/about-us" exact component={Aboutt} />
      <Route path="/form" exact component={Form}/>
      <Route path="/whymarslab" exact component={Whymarslab} />
      <Route path="/index" exact component={Index} />
      <Route path="/team" exact component={Leadership} />
      <Route path="/cloud" exact component={Cloud1} />
      <Route path="/Footer2" exact component={Footer2}/>

      {/* services */}
      <Route path="/services" exact component={Service} />
      <Route path="/cloudservice" exact component={CloudService} />
      <Route path="/privatecloudservice" exact component={PrivateCloudService} />
      {/* <Route path="/publiccloudservice" exact component={PublicCloudService} /> */}
      <Route path="/modernoperations" exact component={ModernOperations} />
      <Route path="/cloudmigration" exact component={CloudMigration} />

      {/* Contact us */}
      <Route path="/contact" exact component={Contactus} />
      <Route path="/" exact component={Index} />

      {/* Inside dropdown content  */}
      <Route path="/applications" exact component={Applications} />
      <Route path="/indexvavbar2" exact component={Indexnavbar2}/>
      <Route path="/Homepage2" exact component={Homepage2}/>
      {/*<Route path="/recrutment-service" exact component={Services2}/>*/}
      <Route path="/About" exact component={About}/>
      <Route path="/About2" exact component={About2}/>
      <Route path="/Approach" exact component={Approach}/>
      <Route path="/Portfolio" exact component={Portfolio}/>
      <Route path="/PrivacyPolicy" exact component={PrivacyPolicy}/>
      <Route path="/terms-of-use" exact component={TermsofUse}/>
      <Route path="/slider" exact component={Slider}/>
      <Route path="/GallerySlider1" exact component={GallerySlider1}/>
      
      


      {/* add redirect for first page */}
      <Redirect from="/Homepage1" to="/" />
      
    </Switch>
  </BrowserRouter>
  </>
  ,
  document.getElementById("root")
);
