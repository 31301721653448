import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import loggo1 from "assets/img/logoo1.png";
import loggo2 from "assets/img/logoo2.png";
import loggo3 from "assets/img/logoo3.png";
import loggo4 from "assets/img/logoo4.png";
import loggo5 from "assets/img/logoo5.png";
import loggo6 from "assets/img/logoo6.png";
import loggo7 from "assets/img/logoo7.png";


const Profile = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1100,
    arrows: false,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    nextArrow: null, // Remove default next arrow
    prevArrow: null, // Remove default prev arrow
  };

  return (
    <section>
      <div className="relative">
        <Slider {...settings}>
          
         <div>
          <img className="xl:w-10/12 w-12/12" src={loggo1} alt=""/>
         </div>

         <div>
          <img className="xl:w-10/12 w-12/12" src={loggo2} alt=""/>
         </div>

         <div>
          <img className="xl:w-10/12 w-12/12" src={loggo3} alt=""/>
         </div>

         <div>
          <img className="xl:w-10/12" src={loggo4} alt=""/>
         </div>

         <div>
          <img className="xl:w-10/12" src={loggo5} alt=""/>
         </div>

         <div>
          <img className="xl:w-10/12" src={loggo6} alt=""/>
         </div>

         <div>
          <img className="xl:w-10/12" src={loggo7} alt=""/>
         </div>

         
          
        </Slider>
      </div>
    </section>
  );
};

export default Profile;
