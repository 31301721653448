import React from 'react';
import icon1 from "assets/img/icon1.png";
import icon2 from "assets/img/icon2.png";
import icon3 from "assets/img/icon3.png";
import icon4 from "assets/img/icon4.png";
import icon5 from "assets/img/icon5.png";
import icon6 from "assets/img/icon6.png";
import icon7 from "assets/img/icon7.png";
import icon8 from "assets/img/icon8.png";
import cardimg1 from "assets/img/cardimg1.png";
import cardimg2 from "assets/img/cardimg2.png";
import cardimg3 from "assets/img/cardimg3.png";
import isoimg5 from "assets/img/isoimg5.jpg";
import isoimg6 from "assets/img/isoimg6.jpg";
import isopic1 from "assets/img/isopic1.jpg";
import pic1 from "assets/img/Pic1.jpg";
import isopic2 from "assets/img/Pic2.jpg";
import isopic3 from "assets/img/Pic3.jpg";
import isopic4 from "assets/img/Pic4.jpg";
import isopic5 from "assets/img/Pic5.jpg";
import isopic6 from "assets/img/Pic6.jpg";
import awards from "assets/img/awards.png";
import officebag from "assets/img/officebag.png";
import experience from "assets/img/experience.png";
export default function View() {
    return (
    
    <section>
     <section className="xl:block lg:block md:block sm:block block figtree">
     <div
          className=" xl:h-200"
          style={{
            backgroundColor:"rgb(49 46 129)",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          >
          {/* First Overlay (Full Coverage) */}
          

          {/* Second Overlay (Centered) */}
          <div
           
          >
            {/* Text inside the second overlay */}
            <h1
              className="text-white text-center figtree xl:text-4xl font-bold xl:pt-20"
              style={{ position: "relative", zIndex: 3 }}
            >
              Services
            </h1>
          </div>
        </div>
      </section>
       {/*section-1*/}
       <section className="xl:block lg:block md:block sm:block block container mx-auto xl:mt-24 figtree xl:px-0 px-6  ">
       <div className='xl:pb-16'>
        <h1 className='text-3xl font-bold xl:ml-5 text-black xl:pb-5 pb-4 xl:pt-0 pt-4'>
        Our List of ISO Standards consulting
        </h1>
        <p className='xl:ml-7'>We facilitate the adoption of ISO standards to standardize organizational operations.</p>
       </div>
        <div className="grid xl:grid-cols-2 grid-cols-1 xl:pt-8 xl:pb-20 pt-16 pb-20">
          <div>
            <div className=" xl:w-11/12 xl:pb-8 pb-8 xl:pt-6 ">
              <img
                className="xl:w-12/12 xl:-ml-7 xl:-mt-16 -mt-16 "
                style={{ zIndex: 1 }}
                src={pic1}
                alt=""
              />
            </div>
          </div>

          <div className="xl:-mt-12 mt-1 xl:px-4 ">
           
            <h1 className="xl:text-3xl font-semibold text xl:pt-4 ">
            1.	ISO 9001: 2015- Quality Management System
            </h1>
            <p className="xl:text-base text2 font-semibold xl:pt-5">
            Latest Version- 2015
            </p>
            <p
              className="xl:text-base text2  xl:pt-4 xl:px-0 px-10"
              style={{ textAlign: "justify" }}
            >
            Proper implementation of ISO 9001:2015 standards will assist the organization to 
            fulfill customer needs and lead to their overall satisfaction. It is applicable to all 
            kinds of manufacturing & service industries. When the organization plans to implement this 
            standard, they can improve their process/products/services effectively in order to improve 
            the organization’s existing Planning, verification, validation and control methods. ISO 9001:2015 
            helps to monitor and review the organization’s management system by adopting various 
            methods, satisfying customers and enhance business performance on a continuous basis. 
            </p>

            
          </div>
        </div>
      </section>


{/*section-2*/}

      <section className="xl:block lg:block md:block sm:block block container mx-auto xl:mt-0 figtree xl:px-0 px-8">
      <div className="grid xl:grid-cols-2 grid-cols-1 xl:pt-8 xl:pb-5 pt-16 lg:pt-0 md:pt-0 md:pb-5 lg:pb-5 pb-20">
      <div className="xl:-mt-12 -mt-24  ">
           
            <h1 className="xl:text-3xl font-semibold text xl:pt-4  ">
            2. ISO 14001: 2015- Environmental Management System
            </h1>
            <p className="xl:text-base text2 font-semibold xl:pt-5">
            Latest Version- 2015
            </p>
            <p
              className="xl:text-base text2  xl:pt-4 xl:px-0 px-10"
              style={{ textAlign: "justify" }}
            >
           This ISO 14001 is applicable for those organization’s whose processes, 
           products and services are impacted by an environment. ISO 14001 addresses 
           the issue by protecting the environment like flora, fauna, water, air, 
           land and other livelihoods and insist to reduce the utilization of natural 
           resources. Proper implementation leads to minimize the costs in an indirect 
           way and to enable the cost reduction from the raw material consumption.
            </p>

            
          </div>
          <div>
            <div className=" xl:w-10/12 xl:pb-8 xl:pt-6 xl:ml-24">
              <img
                className="xl:w-12/12 xl:ml-10  xl:-mt-16 "
                style={{ zIndex: 1 }}
                src={isopic2}
                alt=""
              />
              
            </div>
          </div>
          </div>
      </section>
     
      {/*section-3*/}
      <section className="xl:block lg:block md:block sm:block block container mx-auto xl:mt-0 figtree xl:px-0 px-8">
        <div className="grid xl:grid-cols-2 grid-cols-1 xl:pt-8 xl:pb-20 pt-1 lg:pt-5 md:pt-5 pb-20 g:pb-5 md:pb-5">
          <div>
            <div className=" xl:w-10/12 xl:pb-8 xl:pt-6">
              <img
                className="xl:w-12/12 xl:-ml-4  xl:-mt-16 -mt-16 relative"
                style={{ zIndex: 1 }}
                src={isopic3}
                alt=""
              />
            </div>
          </div>

          <div className="xl:-mt-12 xl:px-4 ">
           
            <h1 className="xl:text-3xl font-semibold text xl:pt-4 ">
            3. ISO 45001: 2018- Occupational Health & Safety Management 
            </h1>
            <p className="xl:text-base text2 font-semibold xl:pt-5">
            Latest Version- 2018
            </p>
            <p
              className="xl:text-base text2  xl:pt-4 xl:px-0 px-4"
              style={{ textAlign: "justify" }}
            >
            It is applicable to all kinds of manufacturing and service industries. 
            It prevents its employees, customers, visitors & other stakeholders from 
            work-related hazards. By implementing this standard, we can readily avoid 
            the risk that can occur from the occurrence of any major or minor human 
            accidents, damage to its property, regulatory penalties, environmental 
            disaster, public threats and to do away with the disappointment from its customers.
            </p>

            
          </div>
        </div>
      </section>

      {/*section-4*/}
      <section className="xl:block lg:block md:block sm:block block container mx-auto xl:mt-0 figtree xl:px-0 px-8">
      <div className="grid xl:grid-cols-2 grid-cols-1 xl:pt-8 xl:pb-20 pt-16 pb-20">
      <div className="xl:-mt-12 -mt-24 mt-12 lg:mt-4 md:mt-4 px-10 ">
           
            <h1 className="xl:text-3xl font-semibold text xl:pt-4 ">
            4. IATF 16949: 2016-  QMS for Automotive organization  
            </h1>
            <p className="xl:text-base text2 font-semibold xl:pt-5">
            Current Version- IATF 16949: 2016
            </p>
            <p
              className="xl:text-base text2  xl:pt-4 xl:px-0 "
              style={{ textAlign: "justify" }}
            >
           All those organizations that are involved in design, development, production & 
           servicing into the automotive supply chain, they are applicable to comply with IATF 
           16949:2016. This standard is based on ISO 9001: 2015 and added specific technical 
           requirements for automotive organizations. Formerly this standard was issued in the name 
           of ISO /TS 16949:2009 and it is replaced by IATF 16949:2016. 
           IATF 16949 emphasizes development of process oriented QMS, this leads to make continual 
           improvement, elimination or prevention of defects, reduction of variation and wastes in 
           the automotive supply chain. The IATF 16949 certified organization certification details will 
           be enlisted in the IATF website. It can be viewed by your prospective customers.

            </p>

            
          </div>
          <div>
            <div className=" xl:w-10/12 xl:pb-8 xl:pt-6 xl:ml-24">
              <img
                className="xl:w-12/12 xl:ml-10  xl:-mt-16 relative"
                style={{ zIndex: 1 }}
                src={isopic4}
                alt=""
              />
            </div>
          </div>
          </div>
      </section>


      {/*section-5*/}
      <section className="xl:block lg:block md:block sm:block block container mx-auto xl:mt-0 figtree xl:px-0 px-8">
        <div className="grid xl:grid-cols-2 grid-cols-1 xl:pt-8 xl:pb-20 pt-16 pb-20">
          <div>
            <div className=" xl:w-10/12 xl:pb-8 xl:pt-6">
              <img
                className="xl:w-12/12 xl:-ml-4  xl:-mt-16 -mt-24 relative"
                style={{ zIndex: 1 }}
                src={isopic5}
                alt=""
              />
             
            </div>
          </div>

          <div className="xl:-mt-12 xl:px-4 ">
           
            <h1 className="xl:text-3xl font-semibold text xl:pt-4 ">
            5.	AS/ EN 9100 (D): 2016- Aerospace Quality Management System
            </h1>
            <p className="xl:text-base text2 font-semibold xl:pt-5">
            Latest Version- (Rev D )2016
            </p>
            <p
              className="xl:text-base text2  xl:pt-4 xl:px-0 px-10"
              style={{ textAlign: "justify" }}
            >
            The organizations who involve in design, development, 
            production & servicing into Aviation, Space & Defense industries, 
            they are applicable to comply with AS 9100 D requirements. 
            This standard emphasizes the product safety and reliability. 
            OEM organizations in the ASD industry require AS/EN 9100 D certification from 
            their suppliers and sub- contractors. This certification is recognized by IAQG 
            (International Aerospace Quality Group). Recognition of IAQG is accepted by 
            Aerospace Original Equipment Manufacturers globally. 

            </p>

            
          </div>
        </div>
      </section>


      {/*section-6*/}
      <section className="xl:block lg:block md:block sm:block block container mx-auto xl:mt-0 figtree xl:px-0 px-8">
      <div className="grid xl:grid-cols-2 grid-cols-1 xl:pt-8 xl:pb-20 pt-16 pb-20">
      <div className="xl:-mt-12 -mt-24 ">
           
            <h1 className="xl:text-3xl font-semibold text xl:pt-4 ">
            6.	ISO 22000:2018-  Food Safety Management System.
            </h1>
            <p className="xl:text-base text2 font-semibold xl:pt-5">
            Current Version: 2018
            </p>
            <p
              className="xl:text-base text2  xl:pt-4 xl:px-0"
              style={{ textAlign: "justify" }}
            >
           ISO 22000 certification is recognized by worldwide customers. 
           Any organization in the food chain industry like those who 
           process foods, Packaging, Storage, Transportation and manufacturing 
           food contact materials, they are applicable for implementing the 
           requirements of this standard.
            </p>

            
          </div>
          <div>
            <div className=" xl:w-10/12 xl:pb-8 xl:pt-6 xl:ml-28">
              <img
                className="xl:w-12/12 xl:ml-5  xl:-mt-16 relative"
                style={{ zIndex: 1 }}
                src={isopic6}
                alt=""
              />
              
            </div>
          </div>
          </div>
      </section>

    </section>
    )
}