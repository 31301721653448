import React, { FC, useState, Fragment, useRef, useEffect } from "react";
import { Link } from "react-router-dom";

import Footer2 from "components/Footers/Footer2";

export default function IndexNavbar() {
  

  return (
    <section className=" ">
      
     
     
        </section>
  );
}
