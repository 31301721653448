import React, { useState } from "react";
import { Link } from "react-router-dom";
import Indexnavbar2 from "components/Navbars/Indexnavbar2";
import Footer2 from "components/Footers/Footer2";
import Cloudslider from "views/Cloudslider";


export default function Portfolio() {
  return (
    <>
      <Indexnavbar2 fixed />

      <section className="xl:block lg:block md:hidden sm:hidden hidden  xl:px-8 container mx-auto xl:pt-32 poppins">
        <div className="xl:w-8/12">
          <div>
            <h1 className="xl:text-5xl  text-black">
              Compare <span className="font-semibold">Sanity</span>
            </h1>
            <p className="xl:text-lg text-black xl:pt-8">
              Explore how Sanity stacks up against other leading content
              management systems (CMSes). Whether you're evaluating options for
              your next project or looking to switch platforms, this section
              will provide you with valuable information about how Sanity
              compares.
            </p>
          </div>
        </div>
      </section>

      <section className="xl:block lg:block md:hidden sm:hidden hidden xl:mt-12  xl:px-8 container mx-auto poppins">
       <div className="grid grid-cols-4 gap-6">
        <div>
            <div className="rounded-md xl:p-4 border-grey  ">
             <p className="xl:text-sm text-black">SANITY VS</p>
             <h1 className="xl:text-2xl text-black font-semibold">Contentful</h1>
             <p className="xl:pt-4 xl:text-xl text-2">➜</p>
            </div>
        </div>

        <div>
            <div className="rounded-md xl:p-4 border-grey  ">
             <p className="xl:text-sm text-black">SANITY VS</p>
             <h1 className="xl:text-2xl text-black font-semibold">Storyblok</h1>
             <p className="xl:pt-4 xl:text-xl text-2">➜</p>
            </div>
        </div>

        <div>
            <div className="rounded-md xl:p-4 border-grey  ">
             <p className="xl:text-sm text-black">SANITY VS</p>
             <h1 className="xl:text-2xl text-black font-semibold">Strapi</h1>
             <p className="xl:pt-4 xl:text-xl text-2">➜</p>
            </div>
        </div>

        <div>
            <div className="rounded-md xl:p-4 border-grey  ">
             <p className="xl:text-sm text-black">SANITY VS</p>
             <h1 className="xl:text-2xl text-black font-semibold">Contentstack</h1>
             <p className="xl:pt-4 xl:text-xl text-2">➜</p>
            </div>
        </div>
       </div>


       <div className="grid grid-cols-4 gap-6 xl:mt-6">
        <div>
            <div className="rounded-md xl:p-4 border-grey  ">
             <p className="xl:text-sm text-black">SANITY VS</p>
             <h1 className="xl:text-2xl text-black font-semibold">Contentful</h1>
             <p className="xl:pt-4 xl:text-xl text-2">➜</p>
            </div>
        </div>

        <div>
            <div className="rounded-md xl:p-4 border-grey  ">
             <p className="xl:text-sm text-black">SANITY VS</p>
             <h1 className="xl:text-2xl text-black font-semibold">Storyblok</h1>
             <p className="xl:pt-4 xl:text-xl text-2">➜</p>
            </div>
        </div>

        <div>
            <div className="rounded-md xl:p-4 border-grey  ">
             <p className="xl:text-sm text-black">SANITY VS</p>
             <h1 className="xl:text-2xl text-black font-semibold">Strapi</h1>
             <p className="xl:pt-4 xl:text-xl text-2">➜</p>
            </div>
        </div>

        <div>
            <div className="rounded-md xl:p-4 border-grey  ">
             <p className="xl:text-sm text-black">SANITY VS</p>
             <h1 className="xl:text-2xl text-black font-semibold">Contentstack</h1>
             <p className="xl:pt-4 xl:text-xl text-2">➜</p>
            </div>
        </div>
       </div>


       <div className="grid grid-cols-4 gap-6 xl:mt-6">
        <div>
            <div className="rounded-md xl:p-4 border-grey  ">
             <p className="xl:text-sm text-black">SANITY VS</p>
             <h1 className="xl:text-2xl text-black font-semibold">Contentful</h1>
             <p className="xl:pt-4 xl:text-xl text-2">➜</p>
            </div>
        </div>

        <div>
            <div className="rounded-md xl:p-4 border-grey  ">
             <p className="xl:text-sm text-black">SANITY VS</p>
             <h1 className="xl:text-2xl text-black font-semibold">Storyblok</h1>
             <p className="xl:pt-4 xl:text-xl text-2">➜</p>
            </div>
        </div>

        <div>
            <div className="rounded-md xl:p-4 border-grey  ">
             <p className="xl:text-sm text-black">SANITY VS</p>
             <h1 className="xl:text-2xl text-black font-semibold">Strapi</h1>
             <p className="xl:pt-4 xl:text-xl text-2">➜</p>
            </div>
        </div>

        <div>
            <div className="rounded-md xl:p-4 border-grey  ">
             <p className="xl:text-sm text-black">SANITY VS</p>
             <h1 className="xl:text-2xl text-black font-semibold">Contentstack</h1>
             <p className="xl:pt-4 xl:text-xl text-2">➜</p>
            </div>
        </div>
       </div>


       <div className="grid grid-cols-4 gap-6 xl:mt-6">
        <div>
            <div className="rounded-md xl:p-4 border-grey  ">
             <p className="xl:text-sm text-black">SANITY VS</p>
             <h1 className="xl:text-2xl text-black font-semibold">Contentful</h1>
             <p className="xl:pt-4 xl:text-xl text-2">➜</p>
            </div>
        </div>

        <div>
            <div className="rounded-md xl:p-4 border-grey  ">
             <p className="xl:text-sm text-black">SANITY VS</p>
             <h1 className="xl:text-2xl text-black font-semibold">Storyblok</h1>
             <p className="xl:pt-4 xl:text-xl text-2">➜</p>
            </div>
        </div>

        <div>
            <div className="rounded-md xl:p-4 border-grey  ">
             <p className="xl:text-sm text-black">SANITY VS</p>
             <h1 className="xl:text-2xl text-black font-semibold">Strapi</h1>
             <p className="xl:pt-4 xl:text-xl text-2">➜</p>
            </div>
        </div>

        <div>
            <div className="rounded-md xl:p-4 border-grey  ">
             <p className="xl:text-sm text-black">SANITY VS</p>
             <h1 className="xl:text-2xl text-black font-semibold">Contentstack</h1>
             <p className="xl:pt-4 xl:text-xl text-2">➜</p>
            </div>
        </div>
       </div>


       <div className="grid grid-cols-4 gap-6 xl:mt-6">
        <div>
            <div className="rounded-md xl:p-4 border-grey  ">
             <p className="xl:text-sm text-black">SANITY VS</p>
             <h1 className="xl:text-2xl text-black font-semibold">Contentful</h1>
             <p className="xl:pt-4 xl:text-xl text-2">➜</p>
            </div>
        </div>

        <div>
            <div className="rounded-md xl:p-4 border-grey  ">
             <p className="xl:text-sm text-black">SANITY VS</p>
             <h1 className="xl:text-2xl text-black font-semibold">Storyblok</h1>
             <p className="xl:pt-4 xl:text-xl text-2">➜</p>
            </div>
        </div>

        <div>
            <div className="rounded-md xl:p-4 border-grey  ">
             <p className="xl:text-sm text-black">SANITY VS</p>
             <h1 className="xl:text-2xl text-black font-semibold">Strapi</h1>
             <p className="xl:pt-4 xl:text-xl text-2">➜</p>
            </div>
        </div>

        <div>
            <div className="rounded-md xl:p-4 border-grey  ">
             <p className="xl:text-sm text-black">SANITY VS</p>
             <h1 className="xl:text-2xl text-black font-semibold">Contentstack</h1>
             <p className="xl:pt-4 xl:text-xl text-2">➜</p>
            </div>
        </div>
       </div>
      </section>


      <section className="xl:block lg:block md:hidden sm:hidden hidden xl:mt-32  container mx-auto xl:px-4 poppins">
        <p className="xl:text-base text-grey-600 font-bold xl:ml-4">Customer stories</p>
        <h1 className="xl:text-5xl text-black xl:ml-4">World-class <span className="font-semibold ">businesses</span>  </h1>
      <div className="xl:mt-12">
         <div>
            <Cloudslider/>
        </div> 
      </div>
      </section>

      <section className="xl:block lg:block md:hidden sm:hidden hidden xl:mt-6 container mx-auto xl:px-8 poppins xl:pb-24 ">
       <div className="flex border-right">
        <p className="xl:text-base font-semibold text-grey-600 xl:ml-2">Read all case studies</p>
        <a href="/">
        <p className="xl:ml-2 xl:text-xl text-2">➜</p>
        </a>
       </div>
      </section>


      
      <Footer2 />
    </>
  );
}
