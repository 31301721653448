import Footer2 from "components/Footers/Footer2";
import Indexnavbar2 from "components/Navbars/Indexnavbar2";
import appro from "assets/img/appro.jpg";
import SME from "assets/img/SME.jpg";
import isostep from "assets/img/isostep.jpeg";
import isimg from "assets/img/isimg.jpg";
export default function isopage() {
    return <section>
    <Indexnavbar2 />
      
      <section className="xl:block lg:block md:block sm:block block figtree">
        <div
          className=" xl:h-200 "
          style={{
            backgroundColor:"rgb(49 46 129)",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          {/* First Overlay (Full Coverage) */}
          

          {/* Second Overlay (Centered) */}
          <div
            
          >
            {/* Text inside the second overlay */}
            <h1
              className="text-white text-center figtree xl:text-4xl font-bold xl:pt-20"
              style={{ position: "relative", zIndex: 3 }}
            >
              Approach
            </h1>
          </div>
        </div>
      </section>
    
      <section className="xl:block lg:block md:block sm:block block container mx-auto xl:mt-24 figtree">
        <div className="grid xl:grid-cols-2 grid-cols-1 xl:pt-16 xl:pb-20 pt- pb-20">
          <div>
            <div className=" xl:w-10/12 lg:w-11/12 md:w-11/12 w-11/12 xl:pb-8 xl:pt-6">
              <img
                className=" rounded-lg xl:w-12/12 lg:w-12/12 md:w-11/12   w-10/12 xl:ml-5 md:ml-16 ml-8 xl:-mt-16 mt-4 "
              
                src={SME}
                alt=""
              />
            </div>
          </div>

          <div className="xl:-mt-12 mt-12 px-10 ">
            <h1 className="xl:text-3xl text-2xl lg:text-3xl md:text-3xl font-semibold text xl:pt-4 xl:pb-0 pb-4 xl:ml-0 ml-8 ">
            Common SMEs Challenges
            </h1>
            <p
              className="xl:text-base text2  xl:pt-4 xl:px-0 px-8 px-5 pl-5"
             
            >
            Small and Medium Enterprises (SMEs) are the lifeblood of 
            economies worldwide. However, they often face unique hurdles 
            when it comes to adopting ISO standards and streamlining 
            operations. Limited resources, lean teams, and a constant 
            focus on day-to-day activities can make these tasks seem daunting

            </p>
            <p
              className="xl:text-base text2  xl:pt-4 xl:px-0 px-8 py-8 px-10"
              style={{ textAlign: "justify" }}
            >
           We understand the unique challenges SMEs face. Our consulting 
           approach is designed to provide a practical, tailored solution 
           that aligns with your specific needs and resources. We're 
           committed to helping you 
            </p>
            <div className="xl:mt-10 text-lg font-semibold">
            <h1 className="xl:mt-1 mt-2 ml-8 xl:ml-0">✔ Simplify Compliance</h1>
            <h1 className="xl:mt-2 mt-2 ml-8 xl:ml-0">✔ Optimize Processes</h1>
            <h1 className="xl:mt-2 mt-2 ml-8 xl:ml-0">✔ Enhance Quality</h1>
            <h1 className="xl:mt-2 mt-2 ml-8 xl:ml-0">✔ Mitigate Risks</h1>
            <h1 className="xl:mt-2 mt-2 ml-8 xl:ml-0">✔ Gain Competitive Edge</h1>
            </div>
          </div>
        </div>
      </section>


      <section className="xl:block lg:block md:block sm:block block xl:mt-1 figtree container mx-auto ">
      <div className="xl:px-0 px-8">
      <div className="flex flex-wrap hidden md:block">
          
          <p className="xl:text-xl text1 xl:ml-1 font-semibold xl:-mt-4 -mt-8">
           Approach for SMEs
          </p>
        </div>
        <div className="flex flex-wrap justify-between">
          <h1 className="xl:text-3xl text-2xl lg:text-3xl md:text-3xl text font-bold xl:mb-0 mb-5  xl:pt-4">
          Process Optimization Approach
          </h1>
          
        </div>

        <div className="grid xl:grid-cols-2 sm:grid-cols-2 ">
          <div>
            <div className=" items-center relative xl:pr-40  xl:mt-10 text-black ">
              <div>
                <h1 className="text-xl lg:text-xl font-semibold xl:mb-2 lg:mb-2 md:mb-2 mb-2 xl:mt-">
                1.	Initial Assessment & Diagnosis
                </h1>
                <h5 className="xl:pb-3">☆ Visit the business to see how it works.</h5>
                <h5 className="xl:pb-3">☆ Map out current processes to identify problems.</h5>
                <h5>☆ Analyze data to find trends and patterns.</h5>
              </div>
              <div>
                <h1 className="text-xl font-semibold xl:mb-2 lg:mb-2 md:mb-2 mb-2 xl:mt-2">
                2.	Problem Identification & Prioritization
                </h1>
                <h5 className="xl:pb-3">☆Find the biggest issues affecting the business, like waste, delays, or poor quality</h5>
                <h5>☆ Prioritize these issues based on their impact.</h5>
              </div>
              <div>
                <h1 className="text-xl font-semibold xl:mb-2 lg:mb-2 md:mb-2 mb-2 xl:mt-2">
                3.	Solution Development & Implementation
                </h1>
              
                <h5 className="xl:pb-3">☆ Use Lean techniques to eliminate waste and improve efficiency.</h5>
                <h5 className="xl:pb-3">☆ Redesign processes to be faster, better, and error-free.</h5>
                <h5 className="xl:pb-3">☆ Use Business required systems to streamline Operations.</h5>
                <h5 className="xl:pb-3">☆ Train employees on new processes and tools.</h5>
                <h5 className="xl:pb-3">☆ Manage change to minimize disruption.</h5>
                <h5>☆ Map out current processes to identify problems.</h5>
                
              </div>
              <div>
                <h1 className="text-xl font-semibold xl:mb-2 lg:mb-2 md:mb-2 mb-2 xl:mt-2">
                4.	Monitoring & Evaluation
                </h1>
                
                <h5 className="xl:pb-3">☆ Track progress using key performance indicators (KPIs).</h5>
                <h5 className="xl:pb-3">☆ Regularly review results and make adjustments.</h5>
                <h5>☆ Encourage continuous improvement and innovation..</h5>
                
              </div>
              <div>
                <h1 className="text-xl font-semibold xl:mb-2 lg:mb-2 md:mb-2 mb-2 xl:mt-2">
                5.	Long Term support & Sustainability
                </h1>
                <h5 className="xl:pb-3">☆ Provide ongoing support to ensure the improvements last.</h5>
                <h5>☆ Share knowledge and skills with the business team.</h5>
              </div>
            </div>
          </div>
          <div className=" xl:w-9/12 lg:w-12/12 xl:w-9/12 xl:pb-8 xl:pt-6 xl:-pr-32 xl:mt-0 mt-8 ">
              <img
                className=" rounded-lg xl:w-12/12 lg:w-12/12 xl:w-12/12  relative xl:-mr-10 xl:ml-36 xl:-mt-16"
                style={{ zIndex: 1 }}
                src={appro}
                alt=""
              />
            </div>
        </div>
        </div>
      </section>
      <section className="xl:mt-28 mt-8 xl:mx-0 mx-8">
      <div className="xl:ml-56 lg:ml-20 xl:pb-10 ">
      <div className="flex ">
          <p className="xl:text-xl text1 xl:ml-1 font-bold xl:-mt-4">
           Approach
          </p>
        </div>
        <div className="flex justify-between">
          <h1 className="xl:text-3xl text-2xl lg:text-3xl md:text-3xl text font-bold  xl:pt-4">
          ISO Standards Implementation
          Approach.
          </h1>
        </div>
        </div>

      <div className="flex flex-wrap  items-center relative xl:pr-40  xl:mt-10 mt-8 text-black ">
      
      <div className=" xl:w-7/12 lg:w-11/12 md:w-12/12 w-12/12 xl:pb-8 xl:pt-6 xl:ml-28 lg:ml-20 md:ml-5 pb-8 ">
              <img
                className=" rounded-lg xl:w-7/12 lg:w-11/12 md:w-12/12  w-12/12   xl:-mr-32 xl:ml- xl:-mr-32 xl:ml-24 xl:-mt-16 xl:mb-10"
                style={{ zIndex: 1 }}
                src={isimg}
                alt=""
              />
            </div>
            <div className=" xl:-ml-20 lg:ml-20 xl:mb-16 lg:mb-16 md:mb-12 mb-8 ">
              <div>
                <h1 className="text-xl font-semibold xl:mb-2 lg:mb-2 md:mb-2 mb-2  xl:mr-20">
                1.	Initial Assessment and Planning
                </h1>
                <p>
                ☆ Understand the client's needs and current practices.<br/>
                ☆ Identify gaps between current practices and ISO standards.<br/>
                ☆ Develop a tailored implementation plan.

                </p>
              </div>
              <div>
                <h1 className="text-xl font-semibold xl:mb-2 lg:mb-2 md:mb-2 mb-2 xl:mt-2">
                2.	Leadership Commitment
                </h1>
                <p>
                ☆ Secure top management's support.<br/>
                ☆ Train leaders on their roles and responsibilities.
                 <br/> ☆ Communicate effectively with all stakeholders.
                </p>
              </div>
              <div>
                <h1 className="text-xl font-semibold xl:mb-2 lg:mb-2 md:mb-2 mb-2 xl:mt-2">
                3.	Documentation and Process Improvement
                </h1>
              
                <p>
                ☆ Create clear and concise documentation.<br/>
                ☆ Map and optimize processes.<br/>
                ☆ Foster a culture of continuous improvement.
                  </p>
                
              </div>
              <div>
                <h1 className="text-xl font-semibold xl:mb-2 lg:mb-2 md:mb-2 mb-2 xl:mt-2">
                4.	Employee Training and Awareness
                </h1>
                
                <p>
                ☆ Provide targeted training to employees.<br/>
                ☆ Emphasize practical application of ISO standards.<br/>
                ☆ Offer ongoing training.

                  </p>
                
              </div>
              <div>
                <h1 className="text-xl font-semibold xl:mb-2 lg:mb-2 md:mb-2 mb-2 xl:mt-2">
                5.	Internal Audits and System Reviews
                </h1>
                <p>
                ☆ Conduct regular internal audits.<br/>
                ☆ Review the management system's performance. <br/>
                ☆ Implement corrective actions.

                  </p>
              </div>
              <div>
                <h1 className="text-xl font-semibold xl:mb-2 lg:mb-2 md:mb-2 mb-2 xl:mt-2">
                6.	Internal Audits and System Reviews
                </h1>
                <p>
                ☆ Choose a reputable certification body.<br/>
                ☆ Prepare for the certification audit.<br/>
                ☆ Maintain certification and continuous improvement.
                  </p>
              </div>
              <div>
                <h1 className="text-xl font-semibold xl:mb-2 lg:mb-2 md:mb-2 mb-2 xl:mt-2">
                7.	Ongoing Support and Improvement
                </h1>
                <p>
                ☆ Provide ongoing support and guidance.<br/>
                ☆ Conduct regular reviews.<br/>
                ☆ Encourage continuous improvement.
                </p>
              </div>
            </div>
            </div>
      </section>

    <Footer2 />
    </section>
}