import Indexnavbar2 from "components/Navbars/Indexnavbar2";
import React, { FC, useState, Fragment, useRef, useEffect } from "react";
import Footer2 from "components/Footers/Footer2";
import clock from "assets/img/clock.png";
import officebag from "assets/img/officebag.png";
import bars from "assets/img/bar-chart.png";
import chess from "assets/img/strategy.png";
import location from "assets/img/isolocation.png";
import mail from "assets/img/mail.png";
import telephone from "assets/img/telephone.png";
import Profile from "views/Profile";
import abtbanner from "assets/img/abtbanner.jpg";
import contactimg1 from "assets/img/contactimg1.jpg";
import serviceimg from "assets/img/serviceimg.jpeg";

export default function Homepage1() {
  return (
    <section>
      <Indexnavbar2 />
      <section className="xl:block lg:block md:block sm:block block figtree">
      <div
          className=" xl:h-200"
          style={{
            backgroundColor:"rgb(49 46 129)",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          {/* First Overlay (Full Coverage) */}
          

          {/* Second Overlay (Centered) */}
          <div
            
          >
            {/* Text inside the second overlay */}
            <h1
              className="text-white text-center figtree xl:text-4xl font-bold xl:pt-20"
              style={{ position: "relative", zIndex: 3 }}
            >
              Contact
            </h1>
          </div>
        </div>
      </section>

      <section className="xl:block lg:block md:block sm:block block figtree container mx-auto xl:mt-20">
        <div className="grid xl:grid-cols-2 grid-cols-1 gap-8">
          <div className="xl:mr-12 xl:pt-1 pt-4 xl:px-0 px-8">
            <h1 className="xl:text-3xl text font-bold  xl:pt-4">
              Get in Touch
            </h1>
            <p className="xl:text-base text2 xl:pt-2">
            Are you an SME struggling with inefficiencies, high costs, <br/>and
            slow growth? Contact us today for a free consultation.
            </p>
            <hr className="border-blue-50 xl:mt-8"></hr>
            <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 grid-cols-1 xl:mt-8 gap-8 xl:px-0  ">
              <div>
                <div className="flex xl:mt-0  mt-4 xl:mr-10 xl:mb-6">
                  <div className="xl:w-16 md:w-16 lg:w-16 w-24 h-16 rounded-md bg3">
                    <div className="center-button">
                      <img className="xl:w-12 xl:pt-4 w-8 pt-4  " src={location} alt="" />
                    </div>
                  </div>
                  <div className="xl:ml-4 ml-3">
                    <h1 className="xl:text-xl font-bold text">Chennai Office</h1>
                    <p className="text-sm text2">
                    "Claity Pathways,<br/>
                   Crizone Business<br/> Centre,
                   64/13,<br/> Mounasamy Madam Street,
                   VenkataPuram,AmbatturOT,<br/>
                   Chennai – 600 053"


                    </p>
                  </div>
                </div>

                <div className="flex xl:mt-2 mt-4 ">
                  <div className="w-16 h-16 rounded-md bg3">
                    <div className="center-button">
                      <img className="w-6 xl:pt-5 pt-5" src={telephone} alt="" />
                    </div>
                  </div>
                  <div className="xl:ml-4 ml-3">
                    <h1 className="xl:text-xl font-bold text">Call us</h1>
                    <p className="text-sm text2">
                    +91 97893 10379  
                    </p>
                  </div>
                </div>
              </div>

              <div>
                <div className="flex xl:mt-0 mt-4 xl:mb-10 ">
                  <div className="xl:w-16 md:w-16 lg:w-16 w-24 h-16 rounded-md bg3">
                    <div className="center-button">
                      <img className="xl:w-8 xl:pt-5 w-8 pt-4" src={location} alt="" />
                    </div>
                  </div>
                  <div className="xl:ml-4 ml-4">
                    <h1 className="xl:text-xl font-bold text">
                      Bangalore Office
                    </h1>
                    <p className="text-sm text2">
                    Keerthi Layout, 1st Cross, Chandapura, 
                    Bengaluru,<br/>
                    Karnataka-560 099.

                    </p>
                  </div>
                </div>

                <div className="flex xl:mt-20 md:mt-8 lg:mt-24 mt-10">
                  <div className="w-16 h-16 rounded-md bg3">
                    <div className="center-button">
                      <img className="xl:w-8 xl:pt-4 " src={mail} alt="" />
                    </div>
                  </div>
                  <div className="xl:ml-4 ml-3">
                    <h1 className="xl:text-xl font-bold text">Email us</h1>
                    <p className="text-sm text2">
                    info@claritypathways.in
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="xl:ml-0 ml-10 lg:ml-24 md:ml-10">
            <form>
              <div className=" ">
                <div className="">
                  <input
                    className="xl:w-12/12 rounded-md bg5 border-4 xl:py-3"
                    type="text"
                    id="text"
                    placeholder="Name"
                  />
                </div>
                <div className="xl:mt-4 mt-3">
                  <input
                    className="xl:w-12/12 rounded-md bg5 border-4 xl:py-3"
                    type="text"
                    id="text"
                    placeholder="Email"
                  />
                </div>
              </div>

              <div className=" xl:mt-4 gap-4 mt-3">
                <div className="">
                  <input
                    className="xl:w-12/12 rounded-md bg5 border-4 xl:py-3"
                    type="number"
                    id="number"
                    placeholder="Phone"
                  />
                </div>
                <div className="xl:mt-4 mt-2">
                  <input
                    className="xl:w-12/12 rounded-md bg5 border-4 xl:py-3"
                    type="text"
                    id="text"
                    placeholder="Your Subject"
                  />
                </div>
              </div>

              <textarea
                className="xl:w-12/12 w-10/12 rounded-md bg5 border-4 xl:mt-4 mt-3"
                id="message"
                name="message"
                placeholder="Your message..."
                rows="6"
              ></textarea>

              <button
                className="bg-gradient font-semibold rounded-md xl:px-8 xl:py-3 px-8 py-3 xl:mt-6 mt-6 xl:mb-0 mb-4 xl:ml-0 ml-20 text-white"
                type="submit"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </section>

      {/*section-2*/}
      <section className="xl:block lg:block md:block sm:block block xl:mt-24 ">
        <div className="">
          <div className="">
            <iframe
              className="xl:w-12/12 w-12/12 xl:h-500 lg:h-700 md:h-700"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d242.8534194680471!2d80.150005!3d13.121047000000015!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a526355e098fc31%3A0xb92cb0a4ff8211ef!2sClarity%20Pathways!5e0!3m2!1sen!2sin!4v1732598246093!5m2!1sen!2sin"
            ></iframe>
          </div>
        </div>
      </section>

      {/*section-3*/}
      <section className="xl:block lg:block md:block sm:block block figtree bg1">
        <div className="xl:pt-20 xl:pb-20 container mx-auto">
          <div>
            <Profile />
          </div>
        </div>
      </section>

      {/*section-4*/}
      <section className="xl:block lg:block md:block sm:block block figtree container mx-auto figtree xl:mt-16 lg:mt-8 md:mt-8 xl:pb-20  lg:pb-20  md:pb-20 pb-10">
        <div className="flex  justify-between">
          <h1 className="xl:text-3xl lg:text-3xl md:text-3xl text font-bold  xl:-pt-2 pt-6 xl:ml-0 ml-32 md:ml-72 lg:ml-96">
            Our Services
          </h1>
        </div>

        <div className="grid xl:grid-cols-2 grid-cols-1 xl:mt-10 ">
          <div class="relative w-full max-w-md overflow-hidden xl:-mr-10 ">
            <img
              class="xl:w-11/12 md:w-12/12 lg:w-11/12 w-10/12 xl:h-350 object-cover mt-5 rounded-xl xl:-mr-10 xl:ml-0 md:ml-14 ml-8"
              src={serviceimg}
              alt=""
            />
          </div>

          {/*2nd grid*/}
          <div>
            <div className="grid xl:grid-cols-2 grid-cols-1 gap-4 xl:pt-4 xl:mx-0 mx-8 pt-6">
              <div class="relative w-full max-w-md overflow-hidden rounded-lg bg3 xl:-ml-4">
               
                <div class=" text xl:p-4 xl:pt-5 p-4 ">
                  
                  <h3 class="xl:text-2xl font-semibold xl:mb-10 text1">
                  About ISO Standards Consulting:
                  </h3>
                  <p class="xl:text-base xl:mb-4">
                  ISO standards are internationally recognized guidelines that help 
                  organizations improve their performance and ensure consistent 
                  quality. By implementing ISO standards, businesses can enhance 
                  their reputation, streamline operations, and reduce costs.
                  </p>
                  <a href="#" class="text1 font-bold text-sm xl:mt-5">
                    Read more &rarr;
                  </a>
                </div>
              </div>

              <div class="relative w-full max-w-md overflow-hidden rounded-lg bg3 xl:ml-1">

                <div class=" text xl:p-4 xl:pt-5 p-4">
                  <h3 class="xl:text-2xl font-semibold xl:mb-8 text1">
                  Optimized Operational Systems for SMEs .
                  </h3>
                  <p class="xl:text-base xl:mb-5">
                  Are you an SME struggling with inefficiencies, high costs, 
                  and slow growth? Our expert consultants can help you streamline 
                  your operations, reduce waste, and boost productivity. We'll work 
                  closely with you to identify bottlenecks, optimize processes, and 
                  implement Lean principles.
                  </p>
                  <a href="#" class="text1 font-bold text-sm ">
                    Read more &rarr;
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer2 />
    </section>
  );
}
