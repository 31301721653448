import React, { useState, useEffect } from "react";
import logo from "assets/img/isologo.jpg";
import location from "assets/img/isolocation.png";
import mail from "assets/img/mail.png";
import Call from "assets/img/call.png";
import { Bars3Icon} from '@heroicons/react/24/solid';


export default function Indexnavbar2() {
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [showHeader1, setShowHeader1] = useState(true);
  const [toggleMenu , setToggleMenu] = useState(false)

  // Handler to toggle the content box
  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  // Scroll event to toggle headers
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setShowHeader1(false);
      } else {
        setShowHeader1(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <section>

      {/*header2 - Fixed on scroll */}
      <section
        className={`xl:block lg:block  sm:hidden  bg-white ${
          !showHeader1 ? "fixed top-0 w-full bg-white shadow-md z-10" : ""
        }`}
      >
      <nav className="hidden xl:block lg:block md:block">
        <div className="grid grid-cols-3 xl:pt-1 xl:pb-1 lg:pt-6 md:pt-6 pb-2   lg:pb-4 md:pb-4 container mx-auto">
          <div>
            <div>
              <a href="/Homepage1">
                <img className="xl:w-6/12 w-6/12 md:w-6/12 xl:ml-0 ml-6 md:ml-4" src={logo} alt="" />
              </a>
            </div>
          </div>
          
          <nav className="">
          <div className="xl:ml-72 xl:mt-0 md:mt-2 lg:mt-2 mt-0">
            <div className="flex xl:ml-42 md:-ml-20  xl:pt-4  xl:mb-3 gap-6 font-semibold">
              <p className="xl:text-lg lg:text-lg text  hovertext">
                <a href="/Homepage1">HOME</a>
              </p>

              <p className="xl:text-lg lg:text-lg text  hovertext">
                <a href="/About">ABOUT</a>
              </p>
              
              <div className="relative inline-block">
              <p className="xl:text-lg lg:text-lg text  hovertext">
              <a href="Approach">APPROACH</a>
              </p>
              </div>

              <div className="relative inline-block">
              <p className="xl:text-lg lg:text-lg text  hovertext">
              <a href="Services">SERVICES</a>
              </p>
              </div>

            </div>
          </div>
          </nav>

          <div>
            <div className="flex justify-end xl:mt-3 lg:mt-2 md:mt-2 mt-0  space-x-3 gap-2 xl:ml-5 md:-ml-3 xl:mr-0 mr-4 ">
              {/* Call Us Button */}
              <a href="tel:+91 9789310379">
                <button className="call-button border-3 text-black rounded-md px-4 py-1 flex items-center space-x-2 text-center xl:text-24 font-semibold">
                  <img className="w-5 " src={Call} alt="" />
                  <span className="xl:ml-2">CALL</span>
                </button>
              </a>

              {/* Contact Us Button */}
              <a href="/contact?">
                <button className=" text-white rounded-md px-3 py-1 flex items-center space-x-2 text-center xl:text-20 font-semibold bg11 ">
                  <img className="w-6" src={mail} alt="" />
                  <span className="xl:ml-2">CONTACT</span>
                </button>
              </a>
            </div>
          </div>
        </div>
        </nav>

        
        <nav className="block md:hidden">
  <div className="flex mt-2 mb-2 ml-4">
    <div className="w-28 mr-44">
      <a href="/Homepage1">
        <img className="w-32" src={logo} alt="" />
      </a>
    </div>
    <div className="-pr-40">
      <button
        onClick={() => setToggleMenu(!toggleMenu)}
        className="lock md:hidden block transition-all duration-500"
      >
        <Bars3Icon className="text-black w-8 h-8 transition-all duration-500" />
      </button>
    </div>
  </div>
  {/* Conditional Nav Menu */}
  {toggleMenu && (
    <nav
      className={`fixed top-0 right-0 h-full w-full bg33 transform transition-transform duration-1500 ease-in-out ${
        toggleMenu ? "translate-x-0" : "translate-x-full"
      }`}
    >
      <div className="flex">
        <div>
          <a href="/Homepage1">
            <img className="w-40 mt-6 h-10 ml-6" src={logo} alt="" />
          </a>
        </div>
        {/* Cancel Button */}
        <div className="mt-6 ml-40 ">
        <button
        onClick={() => setToggleMenu(false)}
        className="absolute top-4 right-4 text-black text-3xl font-bold"
      >
        &times;
      </button>
        </div>
      </div>
      <ul className="p-5 mt-2">
        <li className="my-2 font-bold ml-6">
          <a href="/Homepage1">Home</a>
        </li>
        <li className="my-2 font-bold ml-6">
          <a href="/About">About</a>
        </li>
        <li className="my-2 font-bold ml-6">
          <a href="/Approach">Approach</a>
        </li>
        <li className="my-2 font-bold ml-6">
          <a href="/Services">Services</a>
        </li>
      </ul>
      <div className="flex mt-4 ml-10">
        {/* Call Us Button */}
        <a href="tel:+91 9789310379">
          <button className="call-button border-3 text-black rounded-md px-4 py-1 flex items-center text-center font-semibold">
            <img className="w-5" src={Call} alt="" />
            <span className="ml-2">CALL</span>
          </button>
        </a>
        {/* Contact Us Button */}
        <a href="/contact?">
          <button className="ml-4 text-white rounded-md px-3 py-1 flex items-center text-center font-semibold bg11">
            <img className="w-6" src={mail} alt="" />
            <span className="ml-2">CONTACT</span>
          </button>
        </a>
      </div>
    </nav>
  )}
  {/* Toggle Button */}
</nav>


       
      </section>
    </section>
  );
}
