import React, { useState } from "react";
import twit from "assets/img/twitt.png";
import logo from "assets/img/isologo.jpg";
import insta from "assets/img/instagra.png";
import whatsapp from "assets/img/whatsapp.png";
export default function Footer2() {
  

  const [activeIndex, setActiveIndex] = useState(null);
 
  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
    return (
      <>
<body class="bg-gray text-white">
  <footer class="xl:px-1 xl:pt-12 md:pt-12 lg:pt-12 ">
  <nav className=" xl:mb-16 md:mb-8 lg:mb-8">
      
      <div className="container mx-auto grid xl:grid-cols-6 md:grid-cols-2 lg:grid-cols-6 grid-cols-1  ">
        <div className="xl:ml-0 md:ml-8 xl:mb-0 md:mb-4">
        <img className='mb-5 xl:w-48 w-40 xl:h-16 h-32 xl:mt-0 md:mt-2 mt-4 lg:mt-6  mt-0  xl:ml-0 lg:ml-6 md:ml-8 ml-8 rounded-md ' src={logo} alt='/Homepage1'/>
            <p className="xl:mb-4 xl:mx-0 mx-8 font-semibold">Streamline your Operations and maximize your potential.</p>
        </div>
       
        <div className="xl:ml-10 lg:ml-14 xl:mt-0 mt-0 md:mt-0 lg:mt-6  hidden md:block ">
            <h3 className="xl:text-xl text-xl font-semibold mb-2">Approach</h3>
            <p className="mb-2 hover:underline "><a href="/Homepage1">Process Optimization<br/>Approach</a></p>
            <p className="mb-2 hover:underline "><a  href="/About">ISO Standards Implementation Approach.</a></p>
            
        </div>
        
        <div className="xl:ml-10 md:ml-16 lg:ml-8 xl:mt-0 md:mt-4 hidden md:block ">
            <h3 class="text-xl font-semibold mb-3">Our Services</h3>
            <p className=' mb-2'>Process Optimization Consultings</p>
            <p className=' mb-2'><a href="mailto:info@example.com" className="hover:underline mb-4">ISO Standards Consultings</a></p>
            <p className=' mb-2'><a href="tel:+18881235678" className="hover:underline mb-2">Training</a></p>
            <p className=' mb-2'><a href="tel:+18881235678" className="hover:underline mb-2">Third Party Audits</a></p>
        </div>
        
        <div className="xl:ml-12  xl:mt-0 md:mt-4   hidden md:block">
            <h3 className="xl:text-xl text-xl  font-semibold mb-2">ISO standards </h3>
            <p className="mb-2 hover:underline "><a href="">ISO 9001:2015</a></p>
            <p className="mb-2 hover:underline "><a  href="">IATF 16949: 2016</a></p>
            <p className="mb-2 hover:underline "><a href="">AS 9100D/ EN 9100:2016</a></p>
            <p className="mb-2 hover:underline "><a  href="">ISO 22000:2018</a></p>
            <p className="mb-2 hover:underline "><a href="">ISO 14001:2015</a></p>
            <p className="mb-2 hover:underline "><a  href="">ISO 45001:2018</a></p>
            
        </div>
        <div className="xl:ml-10 lg:ml-2 xl:mb-0 md:mb-8 md:ml-16  xl:mt-0 md:mt-6 hidden md:block">
            <h3 className="xl:text-xl text-xl font-semibold mb-2">Online Services </h3>
            <p className="mb-2 hover:underline "><a href="">Online Recorded Courses</a></p>
            <p className="mb-2 hover:underline "><a  href="">Documentation Tools Kits</a></p>
            <p className="mb-2 hover:underline "><a  href="">Private Growth Catalyst Community</a></p>
            
        </div>

        <div className="xl:ml-10 xl:mb-0 md:mb-8 xl:mt-0 md:mt-6 hidden md:block">
            <h3 className="xl:text-xl text-xl font-semibold mb-2">Contact Us </h3>
            <p className="mb-2 hover:underline "><a href="">+91 97893 10379</a></p>
            <p className="mb-2 hover:underline "><a  href="">info@claritypathways.in</a></p>
            <p className="mb-2 hover:underline "><a  href="">https://claritypathways.in/</a></p>
            
        </div>



        <div className="xl:ml-0 ml-4">
        <div className="">
        <div className="ml-32 mt-4 block md:hidden block ">
        <button>
            <h3 onClick={() => toggleAccordion(1)} className="xl:text-xl text-xl font-semibold mb-2">Approach</h3></button>
            {activeIndex === 1 && (
              <div>
            <p className="mb-2 mt-3 hover:underline "><a href="/Homepage1">Process Optimization<br/>Approach</a></p>
            <p className="mb-2 hover:underline "><a  href="/About">ISO Standards Implementation Approach.</a></p>
            </div>
            )}
            
        </div>
        </div>

        
        <div className="ml-30 mt-4 block md:hidden block">
        <button>
            <h3 onClick={() => toggleAccordion(2)} class="text-xl font-semibold mb-3">Our Services</h3></button>
            {activeIndex === 2 && (
              <div className="">
            <p className=' mb-2 mt-3'>SME's Process Optimization</p>
            <p className=' mb-2'><a href="mailto:info@example.com" className="hover:underline mb-4">ISO Standards Consultings</a></p>
            <p className=' mb-2'><a href="tel:+18881235678" className="hover:underline mb-2">Training</a></p>
            <p className=' mb-2'><a href="tel:+18881235678" className="hover:underline mb-2">Third Party Audits</a></p>
            </div>
            )}
        </div>
        
        <div className="ml-28 mt-4 block md:hidden block">
        <button>
            <h3 onClick={() => toggleAccordion(3)} className="xl:text-xl text-xl font-semibold mb-2">ISO standards </h3></button>
            {activeIndex === 3 && (
              <div>
            <p className="mb-2 mt-3 hover:underline "><a href="">ISO 9001:2015</a></p>
            <p className="mb-2 hover:underline "><a  href="">IATF 16949: 2016</a></p>
            <p className="mb-2 hover:underline "><a href="">AS 9100D/ EN 9100:2016</a></p>
            <p className="mb-2 hover:underline "><a  href="">ISO 22000:2018</a></p>
            <p className="mb-2 hover:underline "><a href="">ISO 14001:2015</a></p>
            <p className="mb-2 hover:underline "><a  href="">ISO 45001:2018</a></p>
            </div>
            )}
            
        </div>
        <div  className="ml-26 mt-4 block md:hidden block ">
        <button>
            <h3 onClick={() => toggleAccordion(4)} className="xl:text-xl text-xl font-semibold mb-2">Online Services </h3></button>
            {activeIndex === 4 && (
              <div>
            <p className="mb-2 mt-3 hover:underline "><a href="">Online Recorded Courses</a></p>
            <p className="mb-2 hover:underline "><a  href="">Documentation Tools Kits</a></p>
            <p className="mb-2 hover:underline "><a href="">Growth Catalyst Community</a></p>
            <p className="mb-2 hover:underline "><a  href="">Private Growth Catalyst Community</a></p>
            </div>
            )}
        </div>
        <div  className="ml-30 mt-4 block md:hidden block mb-6">
        <button>
            <h3 onClick={() => toggleAccordion(5)} className="xl:text-xl text-xl font-semibold mb-2">Contact Us </h3></button>
            {activeIndex === 5 && (
              <div>
            <p className="mb-2 mt-3 hover:underline "><a href="">+91 97893 10379</a></p>
            <p className="mb-2 hover:underline "><a  href="">info@claritypathways.in</a></p>
            <p className="mb-2 hover:underline "><a href="">https://claritypathways.in</a></p>
           
            </div>
            )}
            </div>
            </div>

        
     
        
    </div>
    </nav>
    <div className=" flex xl:w-full xl:h-24 bg10 xl:pl-56 lg:pl-8 md:pl-8 ">
      <div className="xl:mt-10 lg:mt-8 md:mt-8 xl:ml-0 lg:ml-20 hidden md:block">
       <h5>© Clarity pathways Consultings Services 2024.</h5>
      </div>
      <div className="flex xl:ml-92 lg:ml-92 md:ml-20 xl:mt-10 mt-6 xl:mb-0 mb-6 ">
    <div className="xl:ml-76 ml-20">
    <a href="https://www.linkedin.com/in/clarity-pathways-9a1245250?utm_source=share&utm_campaign=share_
    via&utm_content=profile&utm_medium=android_app"><svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path fill-rule="evenodd" clip-rule="evenodd" d="M16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0ZM21.7583 23.55C22.8083 23.525 23.8333 23.525 24.8833 23.55C25.1083 23.55 25.1583 23.475 25.1583 23.275V17.3C25.1583 16.825 25.1333 16.35 25.0583 15.875C24.7583 13.875 23.5583 12.575 21.6833 12.275C20.0583 12.025 18.6833 12.425 17.7083 13.85L17.5833 13.975V12.7C17.5833 12.5 17.5333 12.45 17.3333 12.45H14.1333C13.9333 12.45 13.8833 12.5 13.8833 12.725C13.9083 14.65 13.9083 16.6 13.9083 18.55C13.9083 20.15 13.9083 21.725 13.8833 23.3C13.8833 23.5 13.9333 23.55 14.1333 23.55H17.3333C17.5083 23.575 17.6083 23.525 17.5833 23.325C17.5665 23.1573 17.5723 23.0009 17.5778 22.8482C17.5806 22.7732 17.5833 22.6991 17.5833 22.625V17.375C17.5833 16.625 17.8083 16 18.3833 15.55C19.4583 14.675 20.9583 15.15 21.3333 16.45C21.4583 16.85 21.4833 17.275 21.4833 17.7V23.275C21.4833 23.5 21.5333 23.55 21.7583 23.55ZM11.8833 18V23.25C11.8833 23.475 11.8333 23.55 11.6083 23.55C10.9083 23.5333 10.2083 23.5389 9.5083 23.5445C9.1583 23.5472 8.8083 23.55 8.4583 23.55C8.2583 23.55 8.2083 23.5 8.2083 23.3V12.7C8.2083 12.525 8.2583 12.45 8.4583 12.45H11.6833C11.9083 12.45 11.9333 12.525 11.9333 12.725C11.8833 14.475 11.8833 16.225 11.8833 18ZM9.6083 10.925C10.7833 11.125 11.8083 10.55 12.0583 9.55001C12.3833 8.22501 11.4583 7.10001 10.0583 7.12501C9.1333 7.10001 8.4083 7.55001 8.1083 8.30001C7.6583 9.50001 8.3583 10.725 9.6083 10.925Z" fill="white"></path>
							</svg></a>
                  </div>
                  <div className="xl:ml-3 ml-3">
    <a href="
     https://x.com/ClarityPathways"><img className="xl:w-8 w-8 h-8 xl:h-8" src={twit}></img></a>
                  </div>
                  <div className="xl:ml-3 ml-3">
    <a href=" https://www.facebook.com/profile.php?id=100085473292430"><svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path fill-rule="evenodd" clip-rule="evenodd" d="M16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0ZM17.0683 16.9354V26.6666L13.152 26.6667V16.9355H9.87728V13.1431H13.1521V10.3463C13.1521 7.1006 15.1344 5.33333 18.0298 5.33333C19.4166 5.33333 20.6086 5.43656 20.9561 5.48261V8.87447L18.9479 8.87539C17.3732 8.87539 17.0683 9.62371 17.0683 10.7217V13.143H20.8237L20.3347 16.9354H17.0683Z" fill="white"></path>
							</svg></a>
                  </div>
                  <div className="xl:ml-3 ml-3">
    <a href=" https://www.youtube.com/channel/UCA6lr3L7pRcvPfx_YTxzXzg"><svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path fill-rule="evenodd" clip-rule="evenodd" d="M16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0ZM25.5881 11.1895C25.3574 10.3328 24.682 9.65735 23.8253 9.42671C22.2602 8.99837 16 8.99837 16 8.99837C16 8.99837 9.73969 8.99837 8.17462 9.41023C7.33443 9.64087 6.6425 10.3328 6.41186 11.1895C6 12.7545 6 16 6 16C6 16 6 19.2619 6.41186 20.8105C6.6425 21.6672 7.31795 22.3426 8.17462 22.5733C9.75616 23.0016 16 23.0016 16 23.0016C16 23.0016 22.2602 23.0016 23.8253 22.5897C24.682 22.3591 25.3574 21.6837 25.5881 20.827C25.9999 19.2619 25.9999 16.0165 25.9999 16.0165C25.9999 16.0165 26.0164 12.7545 25.5881 11.1895ZM14.0066 18.9983L19.2125 16L14.0066 13.0016V18.9983Z" fill="white"></path>
							</svg></a>
                  </div>
                  <div className="xl:ml-3 ml-3">
    <a href="
     https://www.instagram.com/claritypathways/"><img className="xl:w-8 xl:h-8 w-8 h-8" src={insta}></img></a>
                  </div>
                  </div>
                  <section
        className="fixed right-0 bottom-0 xl:mb-0 lg:mb-0 md:mb-12 mb-4 xl:mr-4 lg:mr-4 md:mr-4 mr-0 xl:p-8 lg:p-4 p-4"
        style={{ zIndex: 999 }}
      >
       
          <div><a href="https://wa.me/919789310379" className="whatsapp_flout" target="_blank">
            <img  className="w-16 animate-float cursor-pointer" src={whatsapp} alt="minion" /></a>
            </div>
       
      </section>
                  
    </div>
    
    
  </footer>
</body>
      </>
    );
  }
