import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const Landing = () => {
  return (
    <section>
      </section>
  );
};

export default Landing;
