import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const PrevArrow = ({ onClick }) => (
  <div
    className="cursor-pointer text-white bg-newshade4 h-8 w-8 rounded-md flex justify-center items-center text-lg absolute right-0 top-0 transform z-10 -mt-16"
    onClick={onClick}
    style={{ right: "50px" }}
  >
    ←
  </div>
);

const NextArrow = ({ onClick }) => (
  <div
    className="cursor-pointer text-white bg-newshade4 h-8 w-8 rounded-md flex justify-center items-center text-lg absolute right-0 top-0 transform z-10 -mt-16"
    onClick={onClick}
    style={{ right: "10px" }}
  >
    →
  </div>
);

const Cloudslider = () => {
  const sliderRef = useRef(null);

  const settings = {
    
        dots: false,
        infinite: true,
        speed: 1500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        centerMode: false,
        centerPadding: "0px", // Ensures no padding on the sides
    nextArrow: <NextArrow onClick={() => sliderRef.current.slickNext()} />,
    prevArrow: <PrevArrow onClick={() => sliderRef.current.slickPrev()} />,
    responsive: [
      {
        breakpoint: 1024, // lg devices
        settings: {
          slidesToShow: 4, // Show 4 slides for lg devices
        },
      },
      {
        breakpoint: 768, // md devices
        settings: {
          slidesToShow: 3, // Show 3 slides for md devices
        },
      },
      {
        breakpoint: 640, // sm devices
        settings: {
          slidesToShow: 2, // Show 2 slides for sm devices
        },
      },
    ],
  };

  return (
    <section className="relative">
    <Slider
      ref={sliderRef}
      {...settings}
      className="" // Adding gap of 2rem (8)
    >
        <div>

        </div>
      </Slider>
    </section>
  );
};

export default Cloudslider;
