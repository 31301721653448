import Footer2 from "components/Footers/Footer2";
import React, { useState } from "react";
import Indexnavbar2 from "components/Navbars/Indexnavbar2";
import abtbanner from "assets/img/abtbanner.jpg";
import abtimg1 from "assets/img/abtimg1.jpg";
import clock from "assets/img/clock.png";
import officebag from "assets/img/officebag.png";
import bars from "assets/img/bar-chart.png";
import chess from "assets/img/strategy.png";
import SliderPage from "views/SliderPage";
import workteam from "assets/img/work-team.png";
import googleads from "assets/img/googleads.png";
import engagement from "assets/img/engagement.png";
import award from "assets/img/award.png";
import working from "assets/img/continuous.png";
import idea from "assets/img/ideas.png";
import audit from "assets/img/audit.png";
import plan from "assets/img/research.png";
import risk from "assets/img/risk.png";
import stra from "assets/img/stra.png";
import work from "assets/img/work.png";
import marketing1 from "assets/img/empowerment.png";
import marketing2 from "assets/img/solution.png";
import marketing3 from "assets/img/identifying.png";
import businessman from "assets/img/businessman.png";
import aboutimg from "assets/img/aboutimg.jpeg";

export default function About() {
  return (
    <section>
      <Indexnavbar2 />
      <section className="xl:block lg:block md:block sm:block block figtree">
        <div
          className=" xl:h-200"
          style={{
            backgroundColor:"rgb(49 46 129)",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          {/* First Overlay (Full Coverage) */}
          

          {/* Second Overlay (Centered) */}
          <div
           
          >
            {/* Text inside the second overlay */}
            <h1
              className="text-white text-center figtree xl:text-4xl font-bold xl:pt-20"
              style={{ position: "relative", zIndex: 3 }}
            >
              About Us
            </h1>
          </div>
        </div>
      </section>

      {/*section-2*/}
      <section className="xl:block lg:block md:block sm:block block figtree container mx-auto xl:mt-24">
        <div className="grid xl:grid-cols-2 grid-cols-1 xl:mx-0 mx-8 ">
          <div>
            <h1 className="xl:text-3xl text-2xl xl:pt-4 pt-4 xl:pb-0 pb-4 text fobnt-bold  font-bold">
            Optimize your Operations & Elevate your Business
            </h1>
            <p
              className="xl:text-base text2 xl:pt-4"
              style={{ textAlign: "justify" }}
            >
              Clarity Pathways Consulting is dedicated to empowering 
              SMEs to achieve operational excellence. We simplify complex 
              processes, optimize systems, and drive sustainable growth. 
              Our mission is to help more than one million businesses deliver 
              exceptional products and services that delight customers worldwide. 
              By tailoring our approach to each client's unique needs, 
              we provide practical solutions and support to overcome 
              challenges and achieve long-term success.
            </p>
            <div className="flex xl:mt-6 lg:mt-6 md:mt-6 mt-4 xl:mb-0 lg:mb-6 md:mb-0  mb-4 xl:ml-0 md:ml-16 lg:ml-96 ml-20 gap-4">
              <button className="xl:text-base rounded-md bg2 text-white xl:px-6 xl:py-3 px-6 py-3 ">
                <a>Book a Service</a>
              </button>
            </div>
            <hr className="border-blue-50 xl:mt-12"></hr>
            <div className="grid xl:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 grid-cols-1 xl:mt-12 lg:mt-12 md:mt-12 gap-4 ">
              <div>
                <div className="flex xl:mt-0 mt-4">
                  <div className=" xl:mr-0 mr-3">
                    <img
                      className="w-24 border-2 p-1 rounded-md"
                      src={bars}
                      alt=""
                    />
                  </div>
                  <div className="xl:ml-8">
                    <h1 className="xl:text-2xl text font-semibold">
                    Financial Growth
                    </h1>
                    <p className="xl:text-base text2 xl:pt-2">
                    Achieve financial growth through cost optimization, 
                    increased efficiency, and driving higher revenue growth.
                    </p>
                  </div>
                </div>

                <div className="flex xl:mt-16 mt-4">
                  <div className="xl:mr-0 mr-3">
                    <img
                      className="w-24 border-2 p-1 rounded-md"
                      src={clock}
                      alt=""
                    />
                  </div>
                  <div className="xl:ml-8  ">
                    <h1 className="xl:text-2xl text font-semibold">
                    Time Saving
                    </h1>
                    <p className="xl:text-base text2 xl:pt-2">
                    Save time by streamlining processes, eliminating redundancies, 
                    and leveraging automation to focus on what truly matters.
                    </p>
                  </div>
                </div>
              </div>

              <div>
                <div className="flex xl:mt-0 mt-4">
                  <div className="xl:mr-0 mr-3">
                    <img
                      className="w-24 border-2 p-1 rounded-md"
                      src={officebag}
                      alt=""
                    />
                  </div>
                  <div className="xl:ml-8">
                    <h1 className="xl:text-2xl text font-semibold">
                    Productivity Improvement
                    </h1>
                    <p className="xl:text-base text2 xl:pt-2">
                    By optimizing workflows, 
                    empowering teams with the 
                    right tools, and minimizing time-consuming tasks.
                    </p>
                  </div>
                </div>

                <div className="flex xl:mt-8 mt-4 xl:mb-0 mb-40">
                  <div className="xl:mr-0 mr-3">
                    <img
                      className="w-24 border-2 p-1 rounded-md"
                      src={chess}
                      alt=""
                    />
                  </div>
                  <div className="xl:ml-8">
                    <h1 className="xl:text-2xl text font-semibold">
                    Simplified Processes
                    </h1>
                    <p className="xl:text-base text2 xl:pt-2">
                    Simplify processes by removing inefficiencies, 
                    automating repetitive tasks, and optimizing workflows to enhance clarity and efficiency.
                    </p>
                  </div>
                </div>
              </div>
            </div>



          </div>

          <div>
            <div className="flex justify-end ">
              <img className="xl:w-9/12 lg:w-11/12 xl:mt-0 lg:mt-6 mt-6 md:mt-6 " src={aboutimg} alt="" />
            </div>
          </div>
        </div>
      </section>

      {/*section-3*/}
      <section className="xl:block lg:block md:block sm:block block xl:mt-24 lg:mt-32 md:mt-10 xl:ml-52 lg:ml-24 xl:ml-0 md:ml-5  figtree container mx-auto">
        <div className="flex ">
          <div class="small-blue-line hidden xl:block"></div>
          <p className="xl:text-xl text1 xl:ml-4 ml-10 font-semibold xl:-mt-4 mt-6">
            What Can We Offer
          </p>
        </div>
        <div className="flex justify-between mt-4 xl:mt-0 xl:ml-0 ml-10">
          <h1 className="xl:text-3xl text-2xl lg:text-3xl md:text-3xl xl:mb-0 mb-4 text font-bold  xl:pt-4">
            Services We're offering
          </h1>
          <div className="flex justify-end hidden xl:block">
            <button className="xl:text-base rounded-md bg-gradient text-white xl:px-6 xl:py-3">
              <a href="Services">View All Services ➔</a>
            </button>
          </div>
        </div>

        <div className="grid xl:grid-cols-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:mt-12 gap-4 xl:mx-0 mx-8">
          <div>
            <div className="flex items-center relative ">
              <div className="bg-white shadow-lg rounded-md p-4 flex justify-center items-center  transform translate-x-6 xl:mt-2">
                <img
                  className="w-12"
                  src={award}
                  alt="Internet Marketing Icon"
                />
              </div>

              <div className="bg3 rounded-md xl:py-8 flex justify-center xl:px-12 xl:-ml-8">
                <h1 className="xl:text-xl font-semibold text-black leading-tight hovertext">
                ISO Cert. Assistance.
                </h1>
              </div>
            </div>
          </div>

          <div>
            <div className="flex  items-center relative ">
              <div className="bg-white shadow-lg rounded-md p-4 flex justify-center items-center  transform translate-x-6 xl:mt-2">
                <img
                  className="w-12"
                  src={googleads}
                  alt="Internet Marketing Icon"
                />
              </div>

              <div className="bg3 rounded-md xl:py-8 flex justify-center xl:px-12 xl:-ml-8">
                <h1 className="xl:text-xl font-semibold text-black leading-tight hovertext">
                Process Improvements
                </h1>
              </div>
            </div>
          </div>


          <div>
            <div className="flex items-center relative ">
              <div className="bg-white shadow-lg rounded-md p-4 flex justify-center items-center  transform translate-x-6 xl:mt-2">
                <img
                  className="w-12"
                  src={audit}
                  alt="Internet Marketing Icon"
                />
              </div>

              <div className="bg3 rounded-md xl:py-8 flex justify-center xl:px-12 xl:-ml-8">
                <h1 className="xl:text-xl font-semibold text-black leading-tight hovertext">
                Internal Auditing
                </h1>
              </div>
            </div>
          </div>


          <div>
            <div className="flex items-center relative ">
              <div className="bg-white shadow-lg rounded-md p-4 flex justify-center items-center  transform translate-x-6 xl:mt-2">
                <img
                  className="w-12"
                  src={stra}
                  alt="Internet Marketing Icon"
                />
              </div>

              <div className="bg3 rounded-md xl:py-8 flex justify-center xl:px-12 xl:-ml-8">
                <h1 className="xl:text-xl font-semibold text-black leading-tight hovertext">
                Training & Development
                </h1>
              </div>
            </div>
          </div>
           
          <div>
            <div className="flex items-center relative ">
              <div className="bg-white shadow-lg rounded-md p-4 flex justify-center items-center  transform translate-x-6 xl:mt-2">
                <img
                  className="w-12"
                  src={risk}
                  alt="Internet Marketing Icon"
                />
              </div>

              <div className="bg3 rounded-md xl:py-8 flex justify-center xl:px-12 xl:-ml-8">
                <h1 className="xl:text-xl font-semibold text-black leading-tight hovertext">
                Gaps & Risk Assessment
                </h1>
              </div>
            </div>
          </div>

          <div>
            <div className="flex items-center relative ">
              <div className="bg-white shadow-lg rounded-md p-4 flex justify-center items-center  transform translate-x-6 xl:mt-2">
                <img
                  className="w-12"
                  src={plan}
                  alt="Internet Marketing Icon"
                />
              </div>

              <div className="bg3 rounded-md xl:py-8 flex justify-center xl:px-12 xl:-ml-8">
                <h1 className="xl:text-xl font-semibold text-black leading-tight hovertext">
                Documentation Development
                </h1>
              </div>
            </div>
          </div>


          <div>
            <div className="flex items-center relative ">
              <div className="bg-white shadow-lg rounded-md p-4 flex justify-center items-center  transform translate-x-6 xl:mt-2">
                <img
                  className="w-12"
                  src={working}
                  alt="Internet Marketing Icon"
                />
              </div>

              <div className="bg3 rounded-md xl:py-8 flex justify-center xl:px-12 xl:-ml-8">
                <h1 className="xl:text-xl font-semibold text-black leading-tight hovertext">
                Continuous Improvement
                </h1>
              </div>
            </div>
          </div>


          <div>
            <div className="flex items-center relative ">
              <div className="bg-white shadow-lg rounded-md p-4 flex justify-center items-center  transform translate-x-6 xl:mt-2">
                <img
                  className="w-12"
                  src={idea}
                  alt="Internet Marketing Icon"
                />
              </div>

              <div className="bg3 rounded-md xl:py-8 flex justify-center xl:px-12 xl:-ml-8">
                <h1 className="xl:text-xl font-semibold text-black leading-tight hovertext">
                Customized Solutions
                </h1>
              </div>
            </div>
          </div>




        </div>
      </section>

      {/*section-4*/}
      <section className="xl:block lg:block md:block sm:block block figtree bg3 xl:mt-24 mt-8">
        <div className="container mx-auto xl:pt-20 pt-4 xl:pb-20 pb-8">
          <p className="xl:text-lg text1 font-semibold text-white text-center">
          
          </p>
          <h1 className="xl:text-3xl text-2xl lg:text-3xl md:text-3xl font-semibold xl:pt-4 xl:pb-0 pb-4 text text-center">
          Our Team
          </h1>
          <div className="flex justify-center">
          <section>
        <div className="grid xl:grid-cols-2 grid-cols-1 lg:grid-cols-2 md:grid-cols-2 xl:mx-0 mx-8 gap-12 xl:mt-10 lg:mt-10 md:mt-10">
        <div className=" rounded-lg shadow-xl  py-4 bg19 xl:px-4 ">
            <div className="center-button">
            <div className="w-16 h-16 rounded-md bg3 ">
              <div className="center-button">
                <img className="w-12 pt-2 " src={businessman} alt="" />
              </div>
              </div>
            </div>
            <h1 className="xl:text-xl text font-bold text-center xl:pt-6">
            Very seasoned Professionals
            </h1>
            <p className="xl:text-base text2 text-center xl:pt-2 ">
        
            </p>
          </div>


          <div className="rounded-lg shadow-xl  py-4 bg19 xl:px-4">
            <div className="center-button">
            <div className="w-16s h-16 rounded-md bg3">
              <div className="center-button">
                <img className="w-16 pt-2" src={work} alt="" />
              </div>
              </div>
            </div>
            <h1 className="xl:text-xl text font-bold text-center xl:pt-6 xl:px-0 px-1">
            Dedicated People specialized in multiple fields
            </h1>
            <p className="xl:text-base text2 text-center xl:pt-2">
           
            </p>
          </div>


          <div className="rounded-lg shadow-xl  py-4 bg19">
            <div className="center-button px-3">
            <div className="w-16 h-16 rounded-md bg3">
              <div className="center-button">
                <img className="w-12 pt-2" src={engagement} alt="" />
              </div>
              </div>
            </div>
            <h1 className="xl:text-xl text font-bold text-center xl:pt-6 ">
            Industry-specific, professional, polite, uphold <br/>our business ethics.
            </h1>
            <p className="xl:text-base text2 text-center xl:pt-2">
           
            </p>
          </div>

          <div className="rounded-lg shadow-xl  py-4 bg19">
            <div className="center-button">
            <div className="w-16 h-16 rounded-md bg3">
              <div className="center-button">
                <img className="w-12 pt-2" src={workteam} alt="" />
              </div>
              </div>
            </div>
            <h1 className="xl:text-xl text font-bold text-center xl:pt-6">
            Responsible for creating a good <br/>working culture. 
            </h1>
            <p className="xl:text-base text2 text-center xl:pt-2">
           
            </p>
          </div>

        </div>
       </section>

          </div>
        </div>
      </section>

      {/*section-5*/}
      <section className="xl:block lg:block md:block sm:block block figtree container mx-auto xl:mt-24 mt-8 pb-8 xl:pb-20">
        <div className="flex justify-center">

        </div>
        <h1 className="xl:text-3xl text-2xl font-semibold xl:pt-4 pt-2 xl:pb-0 pb-6 text text-center">
         Our customers enjoy the following benefits
        </h1>
        {/* <div className="xl:mt-12">
          <SliderPage />
        </div> */}

       <section>
        <div className="grid xl:grid-cols-3 grid-cols-1 xl:mx-0 mx-8 gap-12 xl:mt-10">
        <div className=" rounded-lg shadow-xl border-3 py-8 bg16 xl:px-4 px-4">
            <div className="center-button">
            <div className="w-16 xl:mb-0 mb-2 h-16 rounded-md bg3 ">
              <div className="center-button">
                <img className="w-12 pt-2 " src={marketing1} alt="" />
              </div>
              </div>
            </div>
            <h1 className="xl:text-xl text font-bold text-center xl:pt-6">
            Growth Catalyst Community
            </h1>
            <p className="xl:text-base text2 text-center xl:pt-2 " style={{ textAlign: "justify" }}>
            Clarity Pathways has formed the Growth Catalyst Community 
            (GCC) for their premium membership. This platform empowers 
            SMEs by providing opportunities for networking, learning, 
            and innovation. GCC members can connect with like-minded 
            entrepreneurs, access valuable resources and mentorship, 
            and explore new ideas and technologies to drive growth.
            </p>
          </div>


          <div className="rounded-lg shadow-xl border-3 py-8 px-4 bg16">
            <div className="center-button">
            <div className="w-16 xl:mb-0 mb-4 h-16 rounded-md bg3">
              <div className="center-button">
                <img className="w-12 pt-2 " src={marketing2} alt="" />
              </div>
              </div>
            </div>
            <h1 className="xl:text-xl text font-bold text-center xl:pt-6">
            Digitally Provide Solutions
            </h1>
            <p className="xl:text-base text2 text-center xl:pt-2" style={{ textAlign: "justify" }}>
            We offer a user-friendly online platform where our clients can 
            access 24/7 online courses on various topics, including 
            bottleneck identification and elimination, waste reduction, 
            business standardization, process optimization, productivity 
            improvement, and quality improvement. Additionally, we 
            conduct regular online meetings to foster interaction, 
            knowledge sharing, and community building.
            </p>
          </div>


          <div className="rounded-lg shadow-xl border-3 py-8  bg16">
            <div className="center-button">
            <div className="w-16 xl:mb-0 mb-2 h-16 rounded-md bg3">
              <div className="center-button">
                <img className="w-12 pt-2" src={marketing3} alt="" />
              </div>
              </div>
            </div>
            <h1 className="xl:text-xl text font-bold text-center xl:pt-6">
            Identify & Remove Bottleneck(GCC)
            </h1>
            <p className="xl:text-base text2 text-center xl:pt-2 px-4" style={{ textAlign: "justify" }}>
            Bottlenecks are limitations or constraints that hinder the 
            flow of work or information within a process. Identifying 
            and eliminating bottlenecks is crucial for improving 
            efficiency, reducing costs, and enhancing overall 
            performance. It can occur in various forms, Such as;

            <p className="xl:mt-3 mt-2">Resource Constraints,  Process Inefficiencies, System 
            Limitations:, Communication Breakdown, Organizational Issues.</p>
            </p>
          </div>

        </div>
       </section>


      </section>
      <Footer2 />
    </section>
  );
}
