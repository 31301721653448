import Footer2 from "components/Footers/Footer2";
import React, { useState } from "react";
import Indexnavbar2 from "components/Navbars/Indexnavbar2";
import View from "Service/View";




export default function SERVICE() {


  return (
    <section>
      <Indexnavbar2 />
      <View/>
      <Footer2 />
    </section>
  );
}
