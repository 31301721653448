import React, { useState } from "react";
import IndexNavbar from "components/Navbars/IndexNavbar";

const AboutUs = () => {
  return (
    <section className="container ">
      <IndexNavbar fixed />
      <div>
        <h1>About Us Page</h1>
        {/* Add your content here */}
      </div>
    </section>
  );
};

export default AboutUs;
